
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaterialModalConfirmComponent } from '../material-modal-confirm/material-modal-confirm.component';
import { RestService } from '../rest.service';
import { setting } from '../../../../setting';
import { ItineraryService } from '../itinerary.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { UserService } from 'app/pages/com.usblick.common/auth/user.service';

@Component({
  selector: 'usb-modal-post-booking',
  templateUrl: './modal-post-booking.component.html',
  styleUrls: ['./modal-post-booking.component.scss']
})
export class ModalPostBookingComponent implements OnInit {

  allItems:any = [];
  selectedItems:any = [];
  step = 1;

  totalPrice:any=0;
  leaveTotal:any;

  paymentMethods:any[] = [];
  details:any = [];

  agency:any;
  itinerary: any;

  getClientsSettingAppBooking: any;
  creditCardPending: boolean = false;
  creditCardPayPartial: boolean = false;
  creditCardDataPending: any= [];
  amountCreditCard: number = 0;
  // Variable de almacenamiento de monto pendiente de pagos con tarjeta de credito pendiente
  amountFilterItineraryId: any = 0;
  amountFilterNull: any = 0;
  partialLeftCreditCard: any = 0;

  amountBLickoinsItineraryPay: number = 0;
  amountCardItineraryPay: number = 0;
  amountDepositItineraryPay: number = 0;
  amountTransferItineraryPay: number = 0;
  consultingPay: boolean = false;
  paymentSync: boolean = false;
  public currencyItinerary: string;
  type: string = '';
  payloadBillingRequest: any;
  payloadBillingRequestInvalid: boolean = true;

  bsModalRef?: BsModalRef;

  constructor(
    public dialogRef: MatDialogRef<ModalPostBookingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog,
    private _restService:RestService,
    private _itineraryService: ItineraryService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
  ) {
  }

  ngOnInit() {

    this.variablesInitial();
    this.setProductsTypes();

  }

/**
 * Método para inicializar variables locales.
 *
 * Este método obtiene datos almacenados en `localStorage` y los inicializa en variables
 * locales para ser usados posteriormente.
 *
 * @property {any[]} getClientsSettingAppBooking - Configuración de clientes obtenida del almacenamiento local.
 * @property {any[]} allItems - Lista de todos los elementos inicializada como un arreglo vacío.
 * @property {any[]} paymentMethods - Métodos de pago inicializados como un arreglo vacío.
 * @property {any} itinerary - Datos del itinerario provenientes de `this.data.itinerary`.
 * @property {string} type - Tipo de operación definida en `this.data.type`.
 * @property {any} agency - Agencia seleccionada obtenida del almacenamiento local.
 * @property {string} currencyItinerary - Moneda del itinerario.
 */
variablesInitial(): void {
  this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
  this.allItems = [];
  this.paymentMethods = [];
  this.itinerary = this.data.itinerary;
  this.type = this.data.type;
  this.agency = JSON.parse(localStorage.getItem('selectedAgency'));
  this.currencyItinerary = this.itinerary.currency;
}

/**
 * Método para configurar los tipos de productos.
 *
 * Este método filtra los elementos del itinerario según el tipo especificado (`payment` o `request-billed`).
 * También realiza llamadas a métodos adicionales para obtener información relacionada con los elementos filtrados.
 *
 * @void
 */
setProductsTypes(): void {
  if (this.type === 'payment') {
    for (let i = 0; i < this.itinerary.items.length; i++) {
      this.itinerary.items[i].selected = false;

      if (
        this.itinerary.items[i].status_id === 2 &&
        !this.itinerary.items[i].isPendigApprovePay &&
        !this.itinerary.items[i].isPaidAgency &&
        !this.itinerary.items[i].isPaidProvider &&
        !this.itinerary.items[i].isBilled
      ) {
        this.allItems.push(this.itinerary.items[i]);
        this.getPaymentCreditCards();
      }
    }

    if (this.allItems.length === 0) {
      // this.dialogRef.close(false);
    }

    this.paymentMasterById(this.itinerary.id);
  } else if (this.type === 'request-billed') {
    for (let i = 0; i < this.itinerary.items.length; i++) {
      this.itinerary.items[i].selected = false;

      if (this.itinerary.items[i].isPaidAgency === true) {
        this.allItems.push(this.itinerary.items[i]);
      }
    }
    this.totalPrice = this.allItems.reduce()
    console.log(this.allItems);
  }
}

/**
 * Método para cambiar el paso actual en la interfaz.
 *
 * @param {number} step - El número del paso al que se desea cambiar.
 */
changeStep(step: number): void {
  this.step = step;
}

/**
 * Método para manejar la selección de un elemento.
 *
 * Si el elemento ya está en la lista de seleccionados, se elimina. Si no está, se agrega.
 * Finalmente, recalcula el precio total.
 *
 * @param {any} item - Elemento que se va a manejar.
 */
handleItem(item: any): void {
  let itemFound = this.selectedItems.find(element => element.id === item.id);
  if (itemFound) {
    let index = this.selectedItems.indexOf(itemFound);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
    }
  } else {
    this.selectedItems.push(item);
  }
  this.setPriceTotal();
}

/**
 * Método para calcular y establecer el precio total.
 *
 * Este método suma los precios netos de todos los elementos seleccionados
 * y redondea el resultado a dos decimales.
 */
setPriceTotal(): void {
  this.totalPrice = 0;

  this.selectedItems.forEach(element => {
    this.totalPrice += parseFloat(element.netPriceAgent);
  });

  this.totalPrice = Math.round(this.totalPrice * 100) / 100;
}

/**
 * Método para cerrar el modal principal.
 *
 * Este método muestra un modal de confirmación para asegurarse de que el usuario desea cerrar.
 * Si el usuario confirma, cierra el modal y, si el paso actual es 4, emite un evento
 * para notificar la actualización de pagos.
 *
 * @modalService-> Servicio para manejar la apertura de modales.
 * @bsModalRef: ModalRef-> Referencia al modal actualmente abierto.
 * @dialogRef: MatDialogRef-> Referencia al modal de diálogo a cerrar.
 * @confirmSubscription: Subscription-> Suscripción para manejar el resultado de confirmación del usuario.
 * @step: number-> Paso actual en el flujo.
 */
close(): void {
  const initialState: ModalOptions = {
    initialState: {
      message: '¿Estás seguro de cerrar?'
    }
  };

  this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);

  const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
    if (result) {
      this.dialogRef.close(true);

      // Emitir el evento solo si el paso actual es 4
      if (this.step === 4) {
        this._itineraryService.emitCloseModalPaymentUpdate(); // Emitir el evento
      }
    } else {
      // Acción opcional si se cancela la confirmación
      // this.openModalExtraLarge(templateNewClient);
    }
  });
}

/**
 * Método para cerrar el modal superior.
 *
 * Similar al método `close`, pero cierra el modal de diálogo con un valor `false`.
 * Si el usuario confirma, cierra el modal y, si el paso actual es 4, emite un evento
 * para notificar la actualización de pagos.
 *
 * @modalService-> Servicio para manejar la apertura de modales.
 * @bsModalRef: ModalRef-> Referencia al modal actualmente abierto.
 * @dialogRef: MatDialogRef-> Referencia al modal de diálogo a cerrar.
 * @confirmSubscription: Subscription-> Suscripción para manejar el resultado de confirmación del usuario.
 * @step: number-> Paso actual en el flujo.
 */
closeTop(): void {
  const initialState: ModalOptions = {
    initialState: {
      message: '¿Estás seguro de cerrar?'
    }
  };

  this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);

  const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
    if (result) {
      this.dialogRef.close(false);

      // Emitir el evento solo si el paso actual es 4
      if (this.step === 4) {
        this._itineraryService.emitCloseModalPaymentUpdate(); // Emitir el evento
      }
    } else {
      // Acción opcional si se cancela la confirmación
      // this.openModalExtraLarge(templateNewClient);
    }
  });
}

/**
 * Método para retroceder un paso en el flujo.
 *
 * Este método actualiza el estado del paso actual (`step`) y, si el paso es 2,
 * resetea la selección de elementos y el precio total.
 *
 * @step: number -> Representa el paso actual en el flujo.
 * @selectedItems: any[] -> Arreglo de elementos seleccionados.
 * @totalPrice: number -> Precio total calculado.
 * @allItems: any[] -> Lista de todos los elementos disponibles.
 */
goBack(): void {
  if (this.step == 2) {
    this.selectedItems = [];
    this.totalPrice = 0;
    this.allItems.forEach(element => {
      element.selected = false;
    });
  }
  this.step = this.step - 1;
}

/**
 * Método para verificar si se puede avanzar al siguiente paso.
 *
 * Evalúa el estado actual basado en el valor de `step` y determina si es posible
 * continuar al siguiente paso en el flujo.
 *
 * @step: number -> Paso actual.
 * @selectedItems: any[] -> Arreglo de elementos seleccionados.
 * @paymentMethods: any[] -> Métodos de pago disponibles.
 * @leaveTotal: number -> Total restante para pagos.
 * @returns: boolean -> `true` si no se puede avanzar, `false` si es posible continuar.
 */
verifyNext(): boolean {
  switch (this.step) {
    case 1:
      if (this.selectedItems.length > 0) {
        return false;
      } else {
        return true;
      }

    case 3:

    if(this.type === 'payment') {
      const paymentCardSuccess = this.paymentMethods.find(element => element.id == 2);
      if (this.leaveTotal === 0 || paymentCardSuccess) {
        const aux = this.paymentMethods.find(element => element.error == true);
        return !!aux;
      } else {
        return true;
      }
    }
    if(this.type === 'request-billed') {
      if(!this.payloadBillingRequestInvalid) {
        return false;
      } else {
        return true;
      }
    }

    default:
      break;
  }
}

/**
 * Método para avanzar al siguiente paso en el flujo.
 *
 * Dependiendo del paso actual (`step`), realiza acciones específicas:
 * - Paso 3: Muestra un modal de confirmación para registrar transacciones de pago.
 * - Otros pasos: Simplemente incrementa el valor de `step`.
 *
 * @step: number -> Paso actual.
 * @paymentMethods: any[] -> Métodos de pago disponibles.
 * @paymentSync: boolean -> Estado de sincronización del pago.
 * @bsModalRef: ModalRef -> Referencia al modal actualmente abierto.
 * @spinner: NgxSpinnerService -> Servicio para mostrar y ocultar el spinner de carga.
 * @setItemDetails(): void -> Método para configurar detalles de los ítems seleccionados.
 * @savePayments(): void -> Método para guardar los pagos registrados.
 */
next(): void {
  switch (this.step) {
    case 3:

    if(this.type === 'payment') {
      let dataModal: any;
      if (this.paymentMethods && this.paymentMethods.length > 0) {
        dataModal = { payment: this.paymentMethods };
      }

      const initialState: ModalOptions = {
        initialState: {
          paymentModal: dataModal,
          currencyItinerary: this.itinerary.currency
        }
      };

      this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);

      const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {
        this.spinner.show();
        if (result) {
          this.paymentSync = true;
          // Si es el paso 3 de pago, registra la transacción
          this.setItemDetails();
          this.savePayments();
        } else {
          this.paymentSync = false;
          this.spinner.hide();
          return;
        }
      });
    }
    if(this.type === 'request-billed') {

      const initialState: ModalOptions = {
        initialState: {
          message: 'Enviar a facturar'
        }
      };

      this.bsModalRef = this.modalService.show(MaterialModalConfirmComponent, initialState);

      const confirmSubscription = this.bsModalRef.content.confirmResult.subscribe((result: boolean) => {


        if(result) {
          this.spinner.show();
          this._restService.doPost('app', 'billingRequest/create', this.payloadBillingRequest).subscribe({
            next: (res: any) => {
              this.spinner.hide();
              this.step = this.step + 1;
            console.log(res);
          },
            error: (error: HttpErrorResponse) => {
              console.log(error)
              this.spinner.hide();
            }
          })
        }
      });
    }
      break;

    default:
      // Cambia al siguiente paso
      this.step = this.step + 1;
      break;
  }
}

/**
 * Método para configurar los detalles de los ítems seleccionados.
 *
 * Este método recopila información relevante de los ítems seleccionados y la almacena
 * en la propiedad `details`. Solo se ejecuta si no hay pagos pendientes o parciales con tarjeta de crédito.
 *
 * @creditCardPending: boolean -> Indica si hay pagos pendientes con tarjeta de crédito.
 * @creditCardPayPartial: boolean -> Indica si hay pagos parciales con tarjeta de crédito.
 * @details: any[] -> Arreglo donde se almacenan los detalles de los ítems seleccionados.
 * @selectedItems: any[] -> Lista de ítems seleccionados.
 */
setItemDetails(): void {
  if (!this.creditCardPending && !this.creditCardPayPartial) {
    this.details = [];

    this.selectedItems.forEach(element => {
      this.details.push({
        category_id: element.category_id,
        id: element.id,
        amount: element.netPriceBooking // totalPrice
      });
    });
  }

}

/**
 * Método para guardar los pagos realizados.
 *
 * Este método crea un objeto con los métodos de pago seleccionados y sus respectivos detalles,
 * luego realiza una solicitud HTTP para enviar la información al backend.
 *
 * @paymentMethods: any[] -> Lista de métodos de pago seleccionados.
 * @agency: { agent_id: number, agency_id: number } -> Información de la agencia.
 * @itinerary: { id: number } -> Información del itinerario.
 * @currencyItinerary: string -> Moneda asociada al itinerario.
 * @details: any[] -> Detalles de los ítems seleccionados.
 * @spinner: NgxSpinnerService -> Servicio para mostrar y ocultar el spinner de carga.
 * @step: number -> Paso actual del flujo.
 * @paymentMasterById(id: number): void -> Método para obtener información de pagos por ID.
 * @doPost(endpoint: string, route: string, data: any): Observable<any> -> Método para realizar solicitudes POST.
 */
savePayments(): void {
  const data = {
    paymentMethods: []
  };

  // Filtra métodos de pago con montos válidos
  this.paymentMethods = this.paymentMethods.filter(payment => payment.amount !== null);
  console.log(this.paymentMethods);

  // Construye la estructura de datos para cada método de pago
  this.paymentMethods.forEach(element => {
    data.paymentMethods.push({
      payment_methods_id: element.id,
      total_amount: String(element.amount),
      agent_id: this.agency.agent_id,
      agency_id: this.agency.agency_id,
      itinerary_id: this.itinerary.id,
      user_id_create: this.agency.agent_id,
      paymentMasterDetails: this.details,
      currency: this.currencyItinerary,
      client_id: this.getClientsSettingAppBooking.id,
      fileArray: element.id == 4 ? element.filesArray : null
    });
  });

  console.log("final data string", JSON.stringify(data));

  // Enviar la solicitud HTTP
  this._restService.doPost('app', 'paymentMasters/create', data).subscribe(
    (response: any) => {
      console.log("responser payment Masters", response);
      if (response.success) {
        this.spinner.hide();
        this.step = this.step + 1;
        this.paymentMasterById(this.itinerary.id);
      } else {
        console.log("error al ejecutar endpoint payment Master");
      }
    },
    error => {
      console.log("error");
    }
  );
}

 /**
 * Maneja el cálculo y la actualización del monto total pendiente de pago (`leaveTotal`).
 *
 * Este método actualiza la propiedad `leaveTotal` a partir de los datos recibidos.
 * También filtra los métodos de pago con montos válidos (no nulos) y actualiza la lista
 * `paymentMethods`, asegurando que no se dupliquen y se mantengan actualizados los datos.
 *
 * @param data {any} - Objeto que contiene los datos de los métodos de pago y el monto auxiliar (`aux`).
 *   - `data.aux` {number} -> Monto total pendiente.
 *   - `data.paymentMethods` {Array<any>} -> Lista de métodos de pago a procesar.
 *
 * @property leaveTotal {number} - Monto total pendiente de pago.
 * @property paymentMethods {Array<any>} - Lista actualizada de métodos de pago.
 */
handleLeaveTotal(data: any): void {
  this.leaveTotal = data.aux;
  console.log("recibo ", data);

  // Filtrar los métodos de pago con montos no nulos
  const filteredPaymentMethods = data.paymentMethods.filter((paymentMethod: any) => paymentMethod.amount !== null);

  // Limpiar el array `this.paymentMethods`
  this.paymentMethods = [];

  // Iterar sobre los datos filtrados
  filteredPaymentMethods.forEach((paymentMethod: any) => {
    // Buscar si ya existe un objeto con el mismo ID en el array
    const existingIndex = this.paymentMethods.findIndex((item: any) => item.id === paymentMethod.id);

    // Actualizar o agregar el método de pago
    if (existingIndex !== -1) {
      this.paymentMethods[existingIndex] = paymentMethod; // Actualizar
    } else {
      this.paymentMethods.push(paymentMethod); // Agregar
    }
  });

  console.log(this.paymentMethods);
}

/**
 * Verifica si una categoría específica está presente en los ítems seleccionados.
 *
 * Este método busca dentro de los ítems seleccionados (`selectedItems`) para determinar
 * si existe al menos un ítem con el nombre de categoría proporcionado.
 *
 * @param category {string} - Nombre de la categoría a verificar.
 *   Valores válidos: "HOTELS", "TOURS", "RENT-A-CAR", "TRANSPORTS", "PTHEMES", "CRUISES", "DISNEY".
 *
 * @returns {boolean} - Devuelve `true` si la categoría está presente, de lo contrario `false`.
 *
 * @property selectedItems {Array<any>} - Lista de ítems seleccionados donde se realiza la búsqueda.
 */
verifyCategory(category: string): boolean {
  let aux;

  switch (category) {
    case "HOTELS":
      aux = this.selectedItems.find(element => element.categoryName === 'HOTELS');
      return !!aux;

    case "TOURS":
      aux = this.selectedItems.find(element => element.categoryName === 'TOURS');
      return !!aux;

    case "RENT-A-CAR":
      aux = this.selectedItems.find(element => element.categoryName === 'RENT-A-CAR');
      return !!aux;

    case "TRANSPORTS":
      aux = this.selectedItems.find(element => element.categoryName === 'TRANSPORTS');
      return !!aux;

    case "PTHEMES":
      aux = this.selectedItems.find(element => element.categoryName === 'PTHEMES');
      return !!aux;

    case "CRUISES":
      aux = this.selectedItems.find(element => element.categoryName === 'CRUISES');
      return !!aux;

    case "DISNEY":
      aux = this.selectedItems.find(element => element.categoryName === 'DISNEY');
      return !!aux;

    default:
      return false;
  }
}

/**
 * Verifica y cuenta la cantidad de ítems seleccionados pertenecientes a una categoría específica.
 *
 * Este método itera sobre la lista de ítems seleccionados (`selectedItems`) y cuenta cuántos ítems
 * pertenecen a la categoría proporcionada. Devuelve el número total de ítems encontrados.
 *
 * @param category {string} - Nombre de la categoría a verificar.
 *   Valores válidos: "HOTELS", "TOURS", "TRANSPORTS", "PTHEMES", "RENT-A-CAR", "CRUISES", "DISNEY".
 *
 * @returns {number} - Cantidad de ítems seleccionados que pertenecen a la categoría especificada.
 *
 * @property selectedItems {Array<any>} - Lista de ítems seleccionados donde se realiza la búsqueda.
 */
verifyQuantityCategory(category: string): number {
  let aux = 0;

  switch (category) {
    case "HOTELS":
      this.selectedItems.forEach(element => {
        if (element.categoryName === 'HOTELS') {
          aux++;
        }
      });
      break;

    case "TOURS":
      this.selectedItems.forEach(element => {
        if (element.categoryName === 'TOURS') {
          aux++;
        }
      });
      break;

    case "TRANSPORTS":
      this.selectedItems.forEach(element => {
        if (element.categoryName === 'TRANSPORTS') {
          aux++;
        }
      });
      break;

    case "PTHEMES":
      this.selectedItems.forEach(element => {
        if (element.categoryName === 'PTHEMES') {
          aux++;
        }
      });
      break;

    case "RENT-A-CAR":
      this.selectedItems.forEach(element => {
        if (element.categoryName === 'RENT-A-CAR') {
          aux++;
        }
      });
      break;

    case "CRUISES":
      this.selectedItems.forEach(element => {
        if (element.categoryName === 'CRUISES') {
          aux++;
        }
      });
      break;

    case "DISNEY":
      this.selectedItems.forEach(element => {
        if (element.categoryName === 'DISNEY') {
          aux++;
        }
      });
      break;

    default:
      break;
  }

  return aux;
}


/**
 * Realiza una solicitud HTTP GET para obtener las tarjetas de crédito pendientes de pago y realiza
 * acciones basadas en la respuesta recibida.
 */
getPaymentCreditCards() {
  // Construye la URL para la solicitud HTTP GET
  const url = 'paymentCreditCards/listPending?' + this.itinerary.items[0].itinerary_id;

  // Realiza la solicitud HTTP GET al backend
  this._restService.doGet_('app', url).subscribe({
      next: (response: any) => {
        console.log(response);
          // Verifica si la respuesta contiene datos
          if (response.data.data.length > 0) {
              // Filtra los elementos de this.allItems basándose en los datos de las tarjetas de crédito pendientes
              this.filterAllItemsWhitCreditCardPending(response.data.data);

              // Ejecuta una acción relacionada con las tarjetas de crédito pendientes
              this.questPaymentCreditCardPending(response.data.data);
          }
      },
      error: (error: HttpErrorResponse) => {
          // Manejo de errores en caso de que ocurra un problema durante la solicitud
      }
  });
}

/**
 * Obtiene los detalles de los pagos asociados a un itinerario y actualiza las variables de montos totales por método de pago.
 *
 * Este método realiza una solicitud GET para obtener una lista de pagos relacionados con un itinerario específico.
 * Itera sobre los resultados para clasificar y acumular los montos de pago según el ID del método de pago.
 *
 * @param itineraryId {number} - ID del itinerario para el cual se recuperan los pagos.
 *
 * @returns {void}
 *
 * @property _restService {any} - Servicio que gestiona solicitudes HTTP en la aplicación.
 * @property amountBLickoinsItineraryPay {number} - Monto total de pagos realizados con BLIKCoins.
 * @property amountCardItineraryPay {number} - Monto total de pagos realizados con tarjeta de crédito.
 * @property amountDepositItineraryPay {number} - Monto total de pagos realizados con depósito.
 * @property amountTransferItineraryPay {number} - Monto total de pagos realizados con transferencia.
 * @property consultingPay {boolean} - Indicador para determinar si los datos de pago se han consultado correctamente.
 */
paymentMasterById(itineraryId: number): void {
  const url = 'paymentMasters/listByItinerary/' + itineraryId;

  this._restService.doGet('app', url).subscribe({
    next: (response: any) => {
      console.log(response);

      // Validar la respuesta y procesar si contiene datos
      if (response && response.length > 0) {
        response.forEach((item, index) => {
          // Clasificar y acumular montos por método de pago
          switch (item.payment_methods_id) {
            case 1: // BLIKCoins
              this.amountBLickoinsItineraryPay += item.total_amount;
              this.amountBLickoinsItineraryPay.toFixed(2); // Formateo a 2 decimales
              break;

            case 2: // Tarjeta de crédito
              this.amountCardItineraryPay += item.total_amount;
              this.amountCardItineraryPay.toFixed(2); // Formateo a 2 decimales
              break;

            case 3: // Depósito
              this.amountDepositItineraryPay += item.total_amount;
              this.amountDepositItineraryPay.toFixed(2); // Formateo a 2 decimales
              break;

            case 4: // Transferencia
              this.amountTransferItineraryPay += item.total_amount;
              this.amountTransferItineraryPay.toFixed(2); // Formateo a 2 decimales
              break;
          }

          // Indicar que la consulta de pagos fue exitosa
          this.consultingPay = true;
        });
      }
    },
    error: (error: HttpErrorResponse) => {
      console.log(error); // Manejo de errores en la solicitud HTTP
    }
  });
}


/**
 * Filtra los elementos de this.allItems basándose en los datos de las tarjetas de crédito pendientes recibidas.
 * @param creditCardData Datos de las tarjetas de crédito pendientes.
 */
private filterAllItemsWhitCreditCardPending(creditCardData: any) {
  // Arreglo temporal para almacenar los ID a filtrar
  const idsToFilter = [];

  // Recorre los datos de las tarjetas de crédito pendientes y agrega los IDs al arreglo temporal
  creditCardData.forEach((payment) => {
      idsToFilter.push(payment.details[0].uid); // Agregar el ID al arreglo temporal
  });

  // Filtra this.allItems una sola vez utilizando los ID almacenados en idsToFilter
  this.allItems = this.allItems.filter(item => !idsToFilter.includes(item.id.toString()));
}


  /**
   * Filtra los datos de los pagos con tarjeta de crédito pendientes para el itinerario actual.
   * Actualiza la variable 'paymentCreditCards' con los datos recibidos y filtra los pagos
   * que coinciden con el ID del itinerario y que aún no tienen un ID de maestro de pago asignado.
   * Si hay pagos pendientes que coinciden con los criterios de filtrado, emite un evento
   * indicando que hay pagos pendientes de tarjeta de crédito para el itinerario actual.
   *
   * @param creditCardData - Un arreglo que contiene los datos de los pagos con tarjeta de crédito.
   * @returns No hay retorno explícito. Sin embargo, la función emite un evento 'paymentCreditCardPending'
   * si hay pagos pendientes de tarjeta de crédito para el itinerario actual.
   */
  private questPaymentCreditCardPending(creditCardData: any[]) {
    // Actualiza la variable 'paymentCreditCards' con los datos recibidos
    let paymentCreditCards = creditCardData;


    // Filtra los pagos que corresponden al ID del itinerario actual
    paymentCreditCards = paymentCreditCards.filter(payment => payment.itinerary_id === this.itinerary.items[0].itinerary_id);
    const payNull: any =  paymentCreditCards.filter(payment => payment.payment_masters_id === null);

    if(paymentCreditCards.length > 0 && payNull.length > 0) {

      let foundMatch: any = false;

      foundMatch = this.foundMatchItemUid(payNull);

      if(!foundMatch) {

        if(this.itinerary.totalPriceByNetReserved > this.amountFilterItineraryId) {
          this.amountFilterItineraryId = this.itinerary.totalPriceByNetReserved - this.amountFilterItineraryId;
          this.creditCardDataPending = paymentCreditCards;
          console.log(this.creditCardDataPending)
          this.creditCardPayPartial = true;
          this.amountSumCreditCardNullPending(paymentCreditCards);
          this.creditCardPayDetails(this.creditCardDataPending);
          console.log(this.details)
          this.step = 3;
        }

      }
    }

    // Filtra los pagos que aún no tienen un ID de maestro de pago asignado
    paymentCreditCards = paymentCreditCards.filter(payment => payment.payment_masters_id === null);

    if (paymentCreditCards.length > 0 && !this.creditCardPayPartial) {
    this.amountFilterNull = this.amountSumCreditCardPending(paymentCreditCards);
      // Verifica si hay pagos pendientes después del filtrado
      this.creditCardDataPending = paymentCreditCards;
      this.creditCardPending = true;
      this.creditCardPendingDetails(this.creditCardDataPending);
      this.step = 3;
      console.log(this.details)
    }
  }

/**
 * Agrega los detalles de una transacción de pago con tarjeta de crédito a this.details si no existen previamente.
 * @param creditCardData Los detalles de la transacción de pago con tarjeta de crédito.
 */
private creditCardPayDetails(creditCardData: any): void {
  // Itera sobre los detalles de la transacción de pago con tarjeta de crédito
  creditCardData[0].details.forEach((element: any) => {
      // Verifica si el elemento ya está en this.details antes de agregarlo
      const exists = this.details.some(detail => detail.id === Number(element.uid));

      // Si el elemento no existe en this.details, lo agrega
      if (!exists) {
          this.details.push({
              category_id: element.category_id,
              id: Number(element.uid),
              amount: this.totalPrice  //totalPrice
          });
      }
  });
}


/**
 * Agrega los detalles de una transacción de tarjeta de crédito pendiente a this.details si no existen previamente.
 * @param creditCardData Los detalles de la transacción de tarjeta de crédito pendiente.
 */
private creditCardPendingDetails(creditCardData: any): void {
  // Itera sobre los detalles de la transacción de tarjeta de crédito pendiente
  creditCardData[0].details.forEach((element: any) => {
      console.log(element); // Registra en la consola el elemento actual

      // Verifica si el elemento ya está en this.details antes de agregarlo
      const exists = this.details.some(detail => detail.id === Number(element.uid));

      // Si el elemento no existe en this.details, lo agrega
      if (!exists) {
          this.details.push({
              category_id: element.category_id,
              id: Number(element.uid),
              amount: this.itinerary.totalPriceByNetReserved //totalPrice
          });
      }
  });
}

private amountSumCreditCardNullPending(paymentCreditCards: any) {

  let creditCardPartialNull = paymentCreditCards.filter(c => c.payment_masters_id === null);

   // Crear un conjunto para almacenar los IDs de los elementos en creditCardPartialNull
   const paymentIds = new Set<number>();
   creditCardPartialNull.forEach(payment => {
       paymentIds.add(Number(payment.details[0].uid));
   });

   // Sumar los valores solo para los elementos en this.itineraryItems que tienen un ID coincidente en paymentIds
   this.itinerary.items.forEach(item => {
       if (paymentIds.has(item.id)) {
        this.partialLeftCreditCard += item.optionNightsNetTotalProf;
       }
   });
}

  private amountSumCreditCardPending(paymentCreditCards: any) {
    let amount: number = 0;
     // Recorrer el array de paymentCreditCards con el fin de sumar los montos de amount para el monto total
      // de pagos pendientes
    paymentCreditCards.forEach((payment: any) => {
      amount += payment.amount;

    });

    return amount;
  }

  private foundMatchItemUid(payNotNull: any): boolean {
    let foundMatch = false;

    // Recorre los elementos de this.itinerary.items
    this.itinerary.items.forEach((item) => {
        // Recorre los objetos en payNotNull.details.uid
        payNotNull.forEach((payment) => {
            // Compara el id del elemento actual de this.itinerary.items con el uid del objeto actual de payNotNull.details.uid
            if (item.id === Number(payment.details[0].uid)) {
                // Si hay una coincidencia, añade el id al array itemArr
                // itemArr.push(item.id); // No parece necesario
                // Verifica si el valor de item.optionNightsNetTotalProf es igual al amount del objeto actual
                if (item.optionNightsNetTotalProf === payment.amount) {
                    foundMatch = true;
                }
            }
        });
    });

    return foundMatch;
}

submitFormRequestBilling(payload: any): void {
  console.log(payload);
  this.payloadBillingRequest = payload[0];
  this.payloadBillingRequestInvalid = false;

}


}
