import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup} from '@angular/forms';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';
import { RestService } from '../../shared/rest.service';
import { UbicacionesService } from './service/ubicaciones.service';
import { Observable } from 'rxjs/Observable';
import { NgxSpinnerService } from 'ngx-spinner';
import { setting } from '../../../../setting';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/profiles/storeProfile';
import { selectPermissions, selectRoles } from 'app/core/profiles/profiles.selectors';

@Component({
    selector: 'usb-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    providers: [UbicacionesService]
})
export class SearchBoxComponent implements OnInit {

    @Input() form: FormGroup;
    @Output() payload = new EventEmitter<any>();
    @Output() results = new EventEmitter<any>();
    @Output() isLoading = new EventEmitter<boolean>();
    @Output() origin = new EventEmitter<any>();
    @Input() _close: boolean;
    @Output() modal = new EventEmitter<string>();
    _roomQty: any;
    rooms: any[];
    _resultsQuery: any;
    tab: string;
    isActive: boolean = false;
    getClientsSettingAppBooking: any;
    activeBtn: string = 'hotels';
    public routeUploadFile: any;
    public sectionSearchBox: any;
    public moduleProfile: any[] = [];
    public searchItineraryAccess: boolean = false;
    public imageBackground: string;


    roles$: Observable<string[]>;
    permissions$: Observable<string[]>;
    permissionsMap: { [key: string]: boolean } = {};

    constructor(
        private _restService: RestService,
        public spinnerService:NgxSpinnerService,
        private store: Store<AppState>
    ) {
      this.tab = 'HOTELS';
      this.routeUploadFile =  setting.routeUploadFile;
      this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    }

    ngOnInit() {
      this.permissions$ = this.store.select(selectPermissions);
      // Despacha las acciones para cargar roles y permisos
      // this.store.dispatch({ type: '[Auth] Load Permissions' });s

      this.permissions$.subscribe(permissions => {
        this.permissionsMap = this.createPermissionsMap(permissions);
      });

        this._roomQty = [];
        this.rooms = [];
        this.initResultQuery('Please perform a search');
        this.routeUploadFile =  setting.routeUploadFile;
        this.sectionSearchBox = JSON.parse(this.getClientsSettingAppBooking?.clients_setting_app_booking?.section_search_box);
        this._restService.optionsTab$.next('HOTELS');
        this.imageBackground = this.getBackgroundImageUrl();
    }

    createPermissionsMap(permissions: any[]): { [key: string]: any } {
      const map: { [key: string]: any } = {};
      permissions.forEach((permission) => {
        // Utiliza el nombre del permiso como clave
        map[permission.name] = permission;
      });
      return map;
    }

    private getBackgroundImageUrl(): string {
      return `url("${this.routeUploadFile.sectionSearchBox?.background_image}")`;
    }

    selectTab(option: string) {
        this._restService.optionsTab$.next(option);
        this.tab = option;
    }

    initResultQuery(message: any) {
        this._resultsQuery = [];
        this._resultsQuery.noResultMessage = message;
        this.results.emit(this._resultsQuery);
    }
    _onPayload(payload: any) {
        this.payload.emit(payload);
    }

    _onResults(results: any) {
        this.results.emit(results);
    }

    _onLoading(isLoading: boolean) {
        this.isLoading.emit(isLoading);
    }
    _onOrigin(origin: any) {
      //  console.log("console origin ", origin);
        this.origin.emit(origin);

    }
    openModal($event) {
        this.modal.emit($event);
    }

    changeActiveBtn(btn: string) {
      this.activeBtn = btn;
    }

  }
