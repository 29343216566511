<div id="modalrooms" style="position: relative;">
  <button type="button" class="close btn-closed" aria-label="Close" (click)="close()">
    <span aria-hidden="true" style="font-size: 50px; font-weight: 100;">&times;</span>
  </button>
  <div class="d-flex" style="gap: 10px; height: 45px;">
      <h1 class="title">
        {{ hotel.title }}
      </h1>
      <div class="d-flex" *ngIf="starRate" >
        <bar-rating [(rate)]="rate" [max]="5" class="d-flex justify-content-start pt-2" style="font-size: 14px;" [readOnly]="true">
          <i *ratingActive class="bi bi-star-fill" style="margin: 2px; color: #feb725"></i>
          <i *ratingInactive class="bi bi-star-fill" style="margin: 2px; color: #d2d2d2"></i>
        </bar-rating>
      </div>
  </div>
  <div class="d-flex justify-content-start">
    <div class="d-flex mr-1">
      <i class="i-placeholder"></i>
    </div>
    <div class="d-flex flex-column justify-content-center">
      <span class="location">{{hotel?.additionalInfo?.location}}</span>
    </div>
  </div>

  <div class="d-flex my-3 justify-content-between">
    <button [ngClass]="{'btn-tabs-active': tab==='rooms'}" class="btn-tabs" (click)="tab='rooms'">HABITACIÓN</button>
    <button [ngClass]="{'btn-tabs-active': tab==='info'}" class="btn-tabs" (click)="tab='info'">INFO</button>
    <button [ngClass]="{'btn-tabs-active': tab==='maps'}" class="btn-tabs" (click)="tab='maps'; mapMarker()">MAPA</button>
    <button [ngClass]="{'btn-tabs-active': tab==='photos'}" class="btn-tabs" (click)="tab='photos'; ">FOTOS</button>
  </div>

  <div [ngClass]="{'d-block': tab==='rooms', 'd-none': tab!='rooms' }">
    <div *ngIf="nuevoObjetoAux.length>0">
      <ng-template ngFor let-room let-i="index" [ngForOf]="nuevoObjetoAux">
        <usb-room [room]='nuevoObjetoAux[i]'
                  [roomsSearch]="roomData"
                  [hotelAdditionalInfo]='hotel.additionalInfo'
                  [allRoom] = 'nuevoObjetoAux'
                  [images]="imgList"
                  [select]="select"
                  [index]="i"
                  [productQty]="productQty"
                  [hotelDescription]="hotel?.description"
                  [reserveMultiHab]="hotel.additionalInfo.reserveMultiHab"
                  [departureDate]="departureDate"
                  (calculateTotalPrice)="calculateTotalPrice($event)"
                  (calculateTotalPriceWithout)="calculateTotalPriceWithout($event)"
                  (selectionChange)="selectionChange($event)"
                  (indexRoom)="selectedIndexRoom($event)">
        </usb-room>
      </ng-template>
    </div>

    <!---
    <div *ngIf="hotel?.additionalInfo?.rooms">
      <ng-template ngFor let-room let-i="index" [ngForOf]="hotel?.additionalInfo?.rooms">
        <usb-room [room]='room' [images]="imgList" [select]="select" [index]="i" [productQty]="productQty"
          [reserveMultiHab]="hotel.additionalInfo.reserveMultiHab" (calculateTotalPrice)="calculateTotalPrice()">
        </usb-room>
      </ng-template>
    </div>
  -->

    <div *ngIf="hotel?.additionalInfo?.roomsPackage">
      <ng-template ngFor let-room let-i="index" [ngForOf]="hotel?.additionalInfo?.roomsPackage">
        <usb-room [room]='room' [images]="imgList" [select]="select" [index]="i" [productQty]="productQty" [hotelAdditionalInfo]='hotel.additionalInfo'
          [reserveMultiHab]="hotel.additionalInfo.reserveMultiHab"
          (calculateTotalPrice)="calculateTotalPrice($event)"></usb-room>
      </ng-template>
    </div>
    <div class="card-total-price text-right mt-5 mb-3 p-2">
      TARIFA NETA: {{currencySelected}} {{totalQty | number:'1.2-2'}}
    </div>
  </div>

  <div [ngClass]="{'d-block': tab==='info', 'd-none': tab!='info' }">
    <div class="md-accordion-custom">
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              DESCRIPCIÓN
            </mat-panel-title>
            <mat-panel-description class="m-0">
              &nbsp;
              <mat-icon class="white-icon">format_list_bulleted</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <p class="text-expand mt-3">
            <!-- | truncate : 130 : "  "-->
            {{ descriptText }}
          </p>


          <div class="d-flex mt-3" style="border-bottom: 0.48px solid #e0e0e0; height:175px;">
            <div class="d-flex justify-content-start align-items-center flex-column"
              style="width: 185px; height: 175px; border-right: 0.48px solid #e0e0e0;">
              <img *ngIf="coverHotels[0]?.thumbnail" [src]="coverHotels[0]?.thumbnail" alt="" style="width: 9rem; height: 10rem; object-fit: cover;" onerror="if (this.src != 'assets/img/app/hotel_default.png') this.src = 'assets/img/app/hotel_default.png';">

              <!-- <p class="title-logo text-center m-0 px-3 pt-1">{{ hotel.hotel_name}}</p> -->
            </div>
            <div class="d-flex justify-content-around align-items-start flex-row w-100" style="width: calc(100% - 180px); height:135px;">
              <div class="d-flex flex-column">
                <div class="d-flex flex-column">
                  <p class="m-0 title-item">Tipo</p>
                  <img class="i-description" src="/assets/img/app/modal/hotel.svg" alt="">
                  <p class="m-0 subtitle-item">Hotel</p>
                </div>
                <div class="d-flex flex-column mt-2">
                  <p class="m-0 mb-1 title-item">Categoría</p>
                  {{ hotel?.additionalInfo?.starRating }}  Estrellas
                <!--- <ngx-stars [readonly]="true" [size]="1" [maxStars]="5" [initialStars]="4" [color]="'#ff9c00'"></ngx-stars> -->
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex flex-column" [ngStyle]="{'background-color': verifyConditionInfo(hotel?.additionalInfo?.numberOfFloors) ? '' : '#efefe9' }">
                  <p class="m-0 title-item">Pisos</p>
                  <img class="i-description" src="/assets/img/app/modal/edificio.svg" alt="">
                  <p class="m-0 subtitle-item">{{ verifyConditionInfo(hotel?.additionalInfo?.numberOfFloors) ?  hotel?.additionalInfo?.numberOfFloors : 'N/A'}}</p>
                </div>
                <div class="d-flex flex-column mt-2" [ngStyle]="{'background-color': verifyConditionInfo(hotel?.additionalInfo?.numberOfrooms) ? '' : '#efefe9'}">
                  <p class="m-0 title-item">Habitaciones</p>
                  <img class="i-description" src="/assets/img/app/modal/cama.svg" alt="">
                  <p class="m-0 subtitle-item">{{ verifyConditionInfo(hotel?.additionalInfo?.numberOfrooms) ?  hotel?.additionalInfo?.numberOfrooms : 'N/A'}} </p>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex flex-column" [ngStyle]="{'background-color': verifyConditionInfo(hotel?.additionalInfo?.constructionYear) ? '' : '#efefe9' }">
                  <p class="m-0 title-item">Años</p>
                  <img class="i-description" src="/assets/img/app/modal/calendar.svg" alt="">
                  <p class="m-0 subtitle-item">{{ verifyConditionInfo(hotel?.additionalInfo?.constructionYear) ? hotel?.additionalInfo?.constructionYear : 'N/A'}} </p>
                </div>
                <div class="d-flex flex-column mt-2" [ngStyle]="{'background-color': verifyConditionInfo(hotel?.additionalInfo?.numberOfRestaurants) ?  '' : '#efefe9'}">
                  <p class="m-0 title-item">Restaurante</p>
                  <img class="i-description" src="/assets/img/app/modal/resto.svg" alt="">
                  <p class="m-0 subtitle-item">{{ verifyConditionInfo(hotel?.additionalInfo?.numberOfRestaurants) ? hotel?.additionalInfo?.numberOfRestaurants : 'N/A'}} </p>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex flex-column" [ngStyle]="{'background-color': hotel?.additionalInfo?.acceptChildren ? '' : '#efefe9'}">
                  <p class="m-0 title-item">Niños</p>
                  <img class="i-description" src="/assets/img/app/modal/child.svg" alt="">
                  <p class="m-0 subtitle-item">{{ hotel?.additionalInfo?.acceptChildren ? hotel?.additionalInfo?.acceptChildren : 'N/A'}} </p>
                </div>
                <div class="d-flex flex-column mt-2" [ngStyle]="{'background-color': hotel?.additionalInfo?.acceptPets ? '' : '#efefe9'}">
                  <p class="m-0 title-item">Mascotas</p>
                  <img class="i-description" src="/assets/img/app/modal/perro.svg" alt="">
                  <p class="m-0 subtitle-item">{{ hotel?.additionalInfo?.acceptPets ?  hotel?.additionalInfo?.acceptPets: 'N/A' }} </p>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex flex-column" [ngStyle]="{'background-color': hotel?.additionalInfo?.suitableForSmoker ? '' : '#efefe9'}">
                  <p class="m-0 title-item">Fumador</p>
                  <img class="i-description" src="/assets/img/app/modal/no-fumar.svg" alt="">
                  <p class="m-0 subtitle-item">{{ hotel?.additionalInfo?.suitableForSmoker ? hotel?.additionalInfo?.suitableForSmoker : 'N/A' }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- amenities-->
          <div class="d-flex flex-column w-100 mt-4 mb-4" *ngIf="amenitiesBoolean">
              <p class="m-0 title-item mb-2">Disponible en el hotel</p>
            <div class="d-flex justify-content-around align-items-start flex-row w-100" style="width: calc(100% - 180px); height:135px;">
              <div class="container">
                <div class='row'>
                  <div class="col-2 mt-2" *ngFor="let amenitie of amenitiesHotelInfo; index as i" style="text-align: center;">
                    <img class="i-description" src="{{amenitie.image}}" alt="{{amenitie.name}}" style="height: 35px; width: 35px; ">
                    <p class="m-0 title-item mt-1">{{amenitie.amenity_name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(0)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              CHECK IN - OUT
            </mat-panel-title>
            <mat-panel-description class="m-0">
              &nbsp;
              <mat-icon class="white-icon">schedule</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="d-flex mt-3">
            <div class="d-flex flex-row w-100">
              <div class="d-flex flex-column" style="width: 30%;">
                <p class="m-0 title-item">Check In</p>
                <p class="m-0 subtitle-item">{{checkIn}}</p>
              </div>
              <div class="d-flex flex-column w-100">
                <p class="m-0 title-item">Check Out</p>
                <p class="m-0 subtitle-item">{{checkOut}}</p>
              </div>
            </div>
          </div>
          <!-- <p class="text-expand mt-3 mb-5">
            Texto aclaratorio de horarios en caso de ser necesario. io io io . io io ioioo ioioioi ioi ooioi isooioioi oioooi
            ioioiiooioioioii.
          </p> -->

        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(0)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              REQUISITOS
            </mat-panel-title>
            <mat-panel-description class="m-0">
              &nbsp;
              <img src="/assets/img/app/modal/clip-check.svg" alt="" width="20px" height="20px" style="margin: 0px 2px;">
            </mat-panel-description>
          </mat-expansion-panel-header>

          <p class="title-item mb-2 mt-3">Requisitos Check In</p>
          <div class="d-flex flex-row flex-wrap">
            <div class="d-flex flex-column" *ngIf="arrRequirements.length >= 0" style="width: calc(100% / 1);">
              <div class="d-flex mb-2 align-items-start" *ngFor="let requirement of arrRequirements">
                <img class="mt-1" style="width: 20px; height: 20px; margin-right: 5px;" src="/assets/img/app/modal/check.svg" alt="">
                <p class="subtitle-item m-0" style="line-height: 1.5;">{{requirement.description}}</p>
              </div>
            </div>
          </div>

          <!-- <p class="title-item mb-2 mt-3">Requisitos vinculados a COVID</p>
          <div class="d-flex flex-row">
            <div class="d-flex flex-column" style="width: calc(100% / 1);" *ngIf="arrRequirements.length >=0">
              <div class="d-flex mb-2 align-items-start" *ngFor="let requirCov of arrRequirementsCovid">
                <img class="mt-1" style="width: 20px; height: 20px; margin-right: 5px;" src="/assets/img/app/modal/check.svg" alt="">
                <p class="subtitle-item m-0" style="line-height: 1.5;">{{requirCov.requirement}}</p>
              </div>
            </div>
          </div>

          <p class="title-item mb-2 mt-3">Requisitos varios u otros</p>
          <div class="d-flex mb-5 flex-row">
            <div class="d-flex flex-column" style="width: calc(100% / 1);" *ngIf="arrRequirementsOther.length >= 0">
              <div class="d-flex mb-2 align-items-start" *ngFor="let otherReq of arrRequirementsOther">
                <img class="mt-1" style="width: 20px; height: 20px; margin-right: 5px;" src="/assets/img/app/modal/check.svg" alt="">
                <p class="subtitle-item m-0" style="line-height: 1.5;">{{otherReq.requirement}}</p>
              </div>
            </div>
          </div> -->

        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(0)" hideToggle *ngIf="howToGetBoolean">
          <mat-expansion-panel-header>
            <mat-panel-title>
              COMO LLEGAR AGREGADOS
            </mat-panel-title>
            <mat-panel-description class="m-0">
              &nbsp;
              <img src="/assets/img/app/modal/itinerary.svg" alt="" width="20px" height="20px" style="margin: 0px 2px;">
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="d-flex flex-column mt-3 mb-5" >
            <div class="d-flex flex-row align-items-end mb-2" *ngFor="let itemToGet of arrHowToGet">
              <img src="/assets/img/app/modal/train.png" alt="" width="20px" height="20px">
              <p class="text-desc ml-2 m-0">{{itemToGet.name}}</p>
              <p class="text-desc m-0 ml-auto">{{itemToGet.address}}</p>
            </div>
          </div>

        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(0)" hideToggle *ngIf="atracttionsBoolean">
          <mat-expansion-panel-header>
            <mat-panel-title>
              ATRACCIONES CERCANAS AGREGADAS
            </mat-panel-title>
            <mat-panel-description class="m-0">
              &nbsp;
              <img src="/assets/img/app/modal/ferris.svg" alt="" width="20px" height="20px" style="margin: 0px 2px;">
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- atracttions  -->
          <strong>
           Cant. atracciones: {{hotel.additionalInfo.atracttions.length}}
          </strong>
          <div class="d-flex flex-column mt-3" *ngFor="let atracttions of hotel.additionalInfo.atracttions; index as i">
            <div class="d-flex flex-row align-items-end">
              <img src="/assets/img/app/modal/shop.svg" alt="" width="20px" height="20px">
              <p class="text-desc m-0 ml-2 mr-2">{{atracttions.name}} {{atracttions.description}}  </p>
              <p class="text-desc m-0 ml-auto">{{atracttions.arrival_time}}</p>
            </div>
          </div>

        </mat-expansion-panel>


        <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(0)" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              OBSERVACIONES
            </mat-panel-title>
            <mat-panel-description class="m-0">
              &nbsp;
              <img src="/assets/img/app/modal/eye.svg" alt="" width="20px" height="20px" style="margin: 0px 2px;">
            </mat-panel-description>
          </mat-expansion-panel-header>

          <p class="title-item mb-0 mt-3" *ngIf="arrFacilitiesAndService.length > 0">Facilities y servicios</p>
          <ng-container *ngFor="let obs of arrFacilitiesAndService">
            <p class="title-item mb-2 mt-1" style="font-size: 12px;">{{obs.facility}}</p>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column" style="width: calc(100% / 1);">
                <div class="d-flex mb-2 align-items-start" *ngFor="let service of obs.services ">
                  <img class="mt-1" style="width: 20px; height: 20px; margin-right: 5px;" src="/assets/img/app/modal/check.svg" alt="">
                  <p class="subtitle-item m-0" style="line-height: 1.5;">{{service}}</p>
                </div>
              </div>
            </div>
          </ng-container>

          <p *ngIf="arrServicesExtras.length > 0"  class="title-item mb-0 mt-3">Instalaciones y servicios</p>
            <div *ngIf="arrServicesExtras" lass="d-flex flex-row">
              <div class="d-flex flex-column" style="width: calc(100% / 1);">
                <div class="d-flex mb-2 align-items-start" *ngFor="let serviceExtra of arrServicesExtras">
                  <!-- <img class="mt-1" style="width: 20px; height: 20px; margin-right: 5px;" src="/assets/img/app/modal/check.svg" alt=""> -->
                  <p class="subtitle-item m-0" style="line-height: 1.5;"><span class="text-capitalize">¿{{serviceExtra.name}}?</span> {{serviceExtra.value === false ? 'NO' : 'SI'}}</p>
                </div>
              </div>
            </div>

          <p class="title-item mb-0 mt-3" *ngIf="otherService.length > 0">Otros servicios</p>
          <ng-container *ngFor="let service of otherService">
            <div class="d-flex flex-row">
              <div class="d-flex" style="width: calc(100% / 1);">
                  <img class="mt-1" style="width: 20px; height: 20px; margin-right: 5px;" src="/assets/img/app/modal/check.svg" alt="">
                  <p class="subtitle-item m-0" style="line-height: 1.5;">{{service.remark}}</p>
              </div>
            </div>
          </ng-container>

          <p class="title-item mb-0 mt-3" *ngIf="arrOthersTax.length > 0">Otros impuestos</p>
          <ng-container *ngFor="let tax of arrOthersTax">
            <div class="d-flex flex-row">
              <div *ngIf="!tax?.included" class="d-flex" style="width: calc(100% / 1);">
                  <img class="mt-1" style="width: 20px; height: 20px; margin-right: 5px;" src="/assets/img/app/modal/check.svg" alt="">
                  <p class="subtitle-item m-0" style="line-height: 1.5;">{{tax?.description}}: {{currencySelected}} {{tax?.amount}}</p>
              </div>
            </div>
          </ng-container>
        </mat-expansion-panel>

        <!-- Comentado hasta que por parte zoco se implemente las preguntas frecuentes -->
         <mat-expansion-panel id="last-ac" [expanded]="step === 5" (opened)="setStep(0)" hideToggle *ngIf="frequentsQBoolean">
          <mat-expansion-panel-header>
            <mat-panel-title>
              PREGUNTAS FRECUENTES
            </mat-panel-title>
            <mat-panel-description class="m-0">
              &nbsp;
              <img src="/assets/img/app/modal/ayuda.svg" alt="" width="20px" height="20px" style="margin: 0px 2px;">
            </mat-panel-description>
          </mat-expansion-panel-header>

            <div class="d-flex flex-column mt-3 mb-5"  *ngFor="let frequentquestions of hotel.additionalInfo.frequentquestions; index as i">
              <p class="text-desc mb-1 font-weight-bold">{{ '¿' + frequentquestions.question + '?' }}</p>
            <p class="text-desc mb-3">{{ frequentquestions.response}}</p>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div [ngClass]="{'d-block': tab==='maps', 'd-none': tab!='maps' }">
    <div class="d-flex flex-column mb-2">
      <div class="d-flex flex-row">
        <div class="col-12 content-dir py-2">
          <p class="direccion-maps mb-0">{{hotel?.additionalInfo?.location}}</p>
        </div>
      </div>
      <div class="d-flex flex-row mt-3">
        <div class="col-12 p-0">
          <!-- Falta Insertar Mapa -->
          <div #gmap style="min-height: 450px; margin: 0xp"></div>
          <!-- <img src="/assets/img/app/maps-modal.png" class="img-map" alt="maps"> -->
        </div>
      </div>
      <!-- <div class="d-flex flex-row mt-3">
        <div class="epigrafe-content d-flex align-items-center p-3">
          <span class="d-flex">{{ hotel?.mapsdescript}} </span>
        </div>
      </div> -->
    </div>
  </div>
  <div [ngClass]="{'d-block': tab==='photos', 'd-none': tab!='photos' }">

  </div>

  <span *ngIf="tab === 'photos'">
    <usb-photos [images]="hotel.additionalInfo.images"></usb-photos>
    </span>

  <div *ngIf="!view_info" class="d-flex pt-4 justify-content-end" style="height: 75px;">
    <!-- <button type="button" class="btn btn-continue" (click)="close()" [disabled]="disableButton">Close</button> -->
    <button type="button" class="btn rounded-pill btn-continue"  (click)="continue()" [disabled]="disableButton">Continuar</button>
  </div>

</div>


