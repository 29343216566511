import { RestService } from "./../../shared/rest.service";
//import { forEach } from '@angular/router/src/utils/collection';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, QueryList, ViewChildren, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { NavigationExtras, Router } from "../../../../node_modules/@angular/router";
import { formatArrivalDate } from "../../utils/Functions";
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "../../pages/com.usblick.common/auth/user.service";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "environments/environment";
import { setting } from "../../../../setting";
import { MatDialog } from "@angular/material";
import { MaterialModalAlertComponent } from "app/shared/material-modal-alert/material-modal-alert.component";
import { ItineraryDetailComponent } from "app/itinerary-detail/itinerary-detail.component";

@Component({
  selector: "usb-body-reservation",
  templateUrl: "./body-reservation.component.html",
  styleUrls: ["./body-reservation.component.scss"],
})
export class BodyReservationComponent implements OnInit, OnDestroy {
  @ViewChild(ItineraryDetailComponent, { static: false }) itineraryDetail: ItineraryDetailComponent;
  @ViewChildren("pax") paxSelect: QueryList<ElementRef>;
  @Input() itineraryItems: any = [];
  @Input() paxes: any = [];
  @Input() itineraryTotal: any;
  @Input() itineraryId: any;
  @Input() partialReserve: boolean = false;
  @Output() paxesForItems = new EventEmitter<any>();
  @Input() itinerary: any;
  @Input() from: any;
  @Input() clientOwner: any;
  @Output() loadedPaxes = new EventEmitter<any>();

  public formatDate = formatArrivalDate;
  productItem: any = [];
  selectedItem = "";
  namehotel: any;
  hotelTitleSelect: string = "";
  // tslint:disable-next-line:max-line-length

  paxesInItems: Array<{
    idPax: any;
    itemId: string;
    clientId: number;
    document_number: string;
    first_name: string;
    last_name: string;
    email: string;
    gender: number;
    country_id: string;
    birth_date: string;
    title: string;
    identificationType: string;
    type: string;
    state_id: string;
    phone: string;
    phone2: string;
    city: string;
    address: string;
    postal_code: string;
    idUnique: number;
    relation_ship_catalogue_id: number;
    holder: string;
  }> = [];

  registerClient: boolean = false;
  resultReserve: any = [];
  permitedReservation: boolean = false;
  reserving: boolean = false;
  emailPrimary: any;
  countryPrimary: any;
  documentPrimary: any;
  isReadOnly: any;
  element: HTMLElement;
  element_: HTMLElement;
  transport: any = [];
  ismeridian = false;
  _form: FormGroup;
  message = [];
  transportLabel: string = "Info";
  transportEx: any;
  isCruise: boolean = false;
  selectedValue = "";
  arrRelationShip: string[] = [];
  arrRelationShipAdults: string[] = [];
  foundRelationship: any;
  paxesToShow: any[] = [];
  foundRelationshipAdults: string[] = [];
  commentsRentacar:string = '';
  public paxGroup: boolean = true;
  public onlyAdults: boolean = false;
  public remarks: any[] = [];
  public commentHotels: any;

  public getClientsSettingAppBooking: any;
  public loading: boolean = false;
  public paxAdultsArr: any[] = [];
  public itemsSync: any;
  public results: boolean = false;
  public itemsArr: any[] = [];
  private shouldContinuePromise: Promise<boolean>;
  private shouldContinueResolver: (value: boolean) => void;
  public itemsMap = new Map<string, any>();
  public itemsPerRoom = new Map<string, any>();
  public separateArrays: any[] = [];
  public itemsNewArr: any[] = [];
  public paxesArr: any[] = [];
  public categoryName: string;
  public formData: any;
  public paxesOriginal: any[] = [];
  public appLoading: boolean = true;




  constructor(
    private route: Router,
    private _restService: RestService,
    private fb: FormBuilder,
    public userService: UserService,
    public ngxSpinner: NgxSpinnerService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {
    this.getClientsSettingAppBooking = JSON.parse(localStorage.getItem(setting.name));
    this.shouldContinuePromise = new Promise<boolean>((resolve) => {
      this.shouldContinueResolver = resolve;
    });
  }


  ngOnInit() {

    this.paxesOriginal = this.paxes;
    console.log("paxes llegenado", this.paxes);
    console.log("itineraryItems llegenado", this.itineraryItems);
    console.log("this.userService.itineraryActive[0].paxlist", this.userService.itineraryActive[0].paxlist);
    this.formData = JSON.parse(localStorage.getItem("form"));
    let foundGroup = false;
    this.itineraryItems.forEach((item) => {
      console.log(item);
      // this.productItem.$item.id = false;
      if (item.categoryName === "HOTELS") {
        this.namehotel = item.title;

        // Crear una clave que incluya hotelId, arrivalDate y departureDate
        const key = `${item.room?.hotelCode}_${item.arrivalDate}_${item.departureDate}`;

        // Verificar si ya existe un array para esta combinación
        if (this.itemsMap.has(key)) {
          // Agregar el item al array existente
          this.itemsMap.get(key)?.push(item);
        } else {
          // Crear un nuevo array y agregar el item
          this.itemsMap.set(key, [item]);
        }

        // Obtener los arrays separados
        this.separateArrays = Array.from(this.itemsMap.values());

        // Construye una cadena única basada en el hotelId
        const keyItems = `${item.room?.hotelCode}_${item.arrivalDate}_${item.departureDate}`;

        // Verifica si ya existe un elemento con el mismo hotelId
        if (!this.itemsPerRoom.has(keyItems)) {
          // Si no existe, agrega el elemento al mapa y al array correspondiente
          this.itemsPerRoom.set(keyItems, item);
        }
        this.itemsNewArr = Array.from(this.itemsPerRoom.values());

        this.itemsNewArr = this.itemsNewArr.map((item, i) => {
          return { ...item, roomsData: this.separateArrays[i] };
        });
      }

      if (item.categoryName === "TOURS") {
        this.namehotel = "Hotel Savoy ACTUALIZIADO";

        // Crear una clave que incluya hotelId, arrivalDate y departureDate
        const key = `${item.toursId}_${item.arrivalDate}_${item.departureDate}_${item.id}`;

        // Verificar si ya existe un array para esta combinación
        if (this.itemsMap.has(key)) {
          // Agregar el item al array existente
          this.itemsMap.get(key)?.push(item);
        } else {
          // Crear un nuevo array y agregar el item
          this.itemsMap.set(key, [item]);
        }

        // Obtener los arrays separados
        this.separateArrays = Array.from(this.itemsMap.values());

        // Construye una cadena única basada en el hotelId
        const keyItems = `${item.toursId}_${item.arrivalDate}_${item.departureDate}_${item.id}`;

        // Verifica si ya existe un elemento con el mismo hotelId
        if (!this.itemsPerRoom.has(keyItems)) {
          // Si no existe, agrega el elemento al mapa y al array correspondiente
          this.itemsPerRoom.set(keyItems, item);
        }
        this.itemsNewArr = Array.from(this.itemsPerRoom.values());

        this.itemsNewArr = this.itemsNewArr.map((item, i) => {
          return { ...item, roomsData: this.separateArrays[i] };
        });
      }

      if (item.categoryName === "RENT-A-CAR") {
        this.namehotel = "Hotel Savoy ACTUALIZIADO";

        // Crear una clave que incluya hotelId, arrivalDate y departureDate
        const key = `${item.rentacarId}_${item.arrivalDate}_${item.departureDate}_${item.id}`;

        // Verificar si ya existe un array para esta combinación
        if (this.itemsMap.has(key)) {
          // Agregar el item al array existente
          this.itemsMap.get(key)?.push(item);
        } else {
          // Crear un nuevo array y agregar el item
          this.itemsMap.set(key, [item]);
        }

        // Obtener los arrays separados
        this.separateArrays = Array.from(this.itemsMap.values());

        // Construye una cadena única basada en el hotelId
        const keyItems = `${item.rentacarId}_${item.arrivalDate}_${item.departureDate}_${item.id}`;

        // Verifica si ya existe un elemento con el mismo hotelId
        if (!this.itemsPerRoom.has(keyItems)) {
          // Si no existe, agrega el elemento al mapa y al array correspondiente
          this.itemsPerRoom.set(keyItems, item);
        }
        this.itemsNewArr = Array.from(this.itemsPerRoom.values());

        this.itemsNewArr = this.itemsNewArr.map((item, i) => {
          return { ...item, roomsData: this.separateArrays[i] };
        });
      }
    });
    if (!this.namehotel) {
      this.namehotel = "";
    }
    if (this.paxes) {
      this.paxes.forEach((pax) => {
        if (this.getDataOfPax(pax.document_number, "relationShip") === null) {
          this.documentPrimary = pax.document_number;
          this.countryPrimary = this.getDataOfPax(pax.document_number, "country_id");
          this.emailPrimary = this.getDataOfPax(pax.document_number, "email");
        }

        this.arrRelationShip.push(pax.relation_ship);
        this.foundRelationship = this.arrRelationShip.filter((element: any) => element === "Child");
        this.arrRelationShipAdults.push(pax.relation_ship);
        this.foundRelationshipAdults = this.arrRelationShip.filter((element: any) => element !== "Child");
      });
    } else {
      this.userService.itineraryActive[0].paxlist.forEach((pax) => {
        if (this.getDataOfPax(pax.document_number, "relationShip") === null) {
          this.documentPrimary = pax.document_number;
          this.countryPrimary = this.getDataOfPax(pax.document_number, "country_id");
          this.emailPrimary = this.getDataOfPax(pax.document_number, "email");
        }
      });

      this.userService.itineraryActive.paxlist.forEach((data) => {});
    }

    this.remarks = [
      { name: "Cama matrimonial" },
      { name: "Habitaciones contiguas" },
      { name: "Cliente llegará tarde" },
      { name: "Dos camas" },
    ];

    this.transport = [
      {
        name: "Flight",
        label: "Flight Code",
        placeholder: "AA908",
        value: "flight",
      },
      {
        name: "Train",
        label: "Ship Name",
        placeholder: "TRSB",
        value: "train",
      },
      {
        name: "Cruise",
        label: "Company Name",
        placeholder: "MSC Sinfonía",
        value: "cruise",
      },
    ];
    this.initForm();

    this.paxes.forEach((element: any) => {
      this.arrRelationShip.push(element.relation_ship);
      this.foundRelationship = this.arrRelationShip.filter((element: any) => element === "Child");
    });

    this.getPaxAdults();

  }

  ngOnDestroy() {

  }



  getPaxAdults() {
    // console.log("this.paxes getPaxAdults", this.paxes);

    this.paxAdultsArr = this.paxes.filter((pax) => pax.type !== "children");
    // console.log("paxAdultsArr", this.paxAdultsArr);
    // setTimeout(() => {
      this.appLoading = false;
    //   this.cdr.detectChanges();
    // }, 1500);
  }

  initForm() {
    this._form = this.fb.group({
      arrivalTime: [null, Validators.required],
      transport: ["", Validators.required],
      idComment: ["", Validators.required],
      idTransport: ["", Validators.required],
      cruiseNumber: ["", Validators.required],
    });
  }

  getRandom() {
    return Math.random().toString(36).substring(7);
  }

  indexPax(i) {}

  errorDialog() {
    const dialogRef = this.dialog.open(MaterialModalAlertComponent, {
      width: "415px",
      data: "Este pasajero ya fue seleccionado, elija otro por favor",
      disableClose: true, // Desactivar el cierre al hacer clic fuera del diálogo
    });
  }

  maxPaxDialog() {
    const dialogRef = this.dialog.open(MaterialModalAlertComponent, {
      width: "415px",
      data: "Usted ya eligio todos los pasajeros para este cuarto",
      disableClose: true, // Desactivar el cierre al hacer clic fuera del diálogo
    });
  }

  maxChildrenDialog() {
    const dialogRef = this.dialog.open(MaterialModalAlertComponent, {
      width: "415px",
      data: "Usted ya eligio el máximo de pasajeros de tipo niño",
      disableClose: true, // Desactivar el cierre al hacer clic fuera del diálogo
    });
  }

  maxAdultsDialog() {
    const dialogRef = this.dialog.open(MaterialModalAlertComponent, {
      width: "415px",
      data: "Usted ya eligio el máximo de pasajeros de tipo adulto",
      disableClose: true, // Desactivar el cierre al hacer clic fuera del diálogo
    });
  }

  setPaxToItem(pax, item, event, index) {
    // console.log(pax);
    // console.log(this.paxesInItems);

    let allowNewPax = this.checkQtyPaxesByCategory(item);
    let email = this.getDataOfPax(pax, "email");
    // let country_id = this.getDataOfPax(pax, "country_id");
    let first_name = this.getDataOfPax(pax, "name");
    let document = pax;

    const selectedOption = event.target.options[event.target.selectedIndex];
    // console.log(selectedOption);
    // Acceder a los valores personalizados
    const firstName = selectedOption.dataset.name;
    const lastName = selectedOption.dataset.last_name;
    const relation_ship = selectedOption.dataset.relation_ship_catalogue_id ? selectedOption.dataset.relation_ship_catalogue_id : null;
    const uid: number = selectedOption.getAttribute("data-idUnique"); // Obtener el valor de 'data-idUnique'
    const birth_date = selectedOption.dataset.birth_date;
    const title = selectedOption.dataset.title;
    const gender = selectedOption.dataset.gender;
    const identificationType = selectedOption.dataset.document_type_id;
    const address = selectedOption.dataset.address;
    let country_id = selectedOption.dataset.country_id;
    const city = selectedOption.dataset.city;
    const state_id = selectedOption.dataset.state_id;
    const phone = selectedOption.dataset.phone;
    const phone_2 = selectedOption.dataset.phone_2;
    const type = selectedOption.dataset.type;
    const clientId = selectedOption.dataset.client_id;
    // console.log(clientId)
    const itemIdArr = this.paxesInItems.filter((c) => c.itemId === item.id);
    const maxPaxAdults = item.paxAdults;
    const maxPaxChilds = item.paxChildren;
    let child = "";
    let exists: boolean = false;
    console.log("relationShip ->", relation_ship);
    if (type === "children") {
      child = "CH";
    }
    let adult = "";
    if (type !== "children") {
      adult = "AD";
    }

    if (pax != null && allowNewPax && event.target.value != null) {
      if (this.paxesInItems.some((val) => val.itemId == item.id) && itemIdArr.length === item.paxAdults + item.paxChildren) {
        exists = true;
        this.maxPaxDialog();
      }

      if (!exists) {
        if (this.paxesInItems.some((val) => val.itemId == item.id && val.idUnique == uid)) {
          exists = true;
          this.errorDialog();
        }
      }
      if (!exists) {
        if (this.paxesInItems.some((val) => val.itemId == item.id && itemIdArr.some((val) => val.type === child))) {
          const childrenPaxesCount = this.paxesInItems.filter((val) => val.itemId == item.id && val.type === "CH").length;

          if (childrenPaxesCount >= maxPaxChilds) {
            this.maxChildrenDialog();
            exists = true;
          }
        }
      }
      if (!exists) {
        if (this.paxesInItems.some((val) => val.itemId == item.id && itemIdArr.some((val) => val.type === adult))) {
          const adultsPaxesCount = this.paxesInItems.filter((val) => val.itemId == item.id && val.type === "AD").length;

          if (adultsPaxesCount >= maxPaxAdults) {
            this.maxAdultsDialog();
            exists = true;
          }
        }
      }
      if (exists) {
        console.log("==>pax ya INCLUIDO en el item");
      } else {
        // obtener datos del pax principal
        if (this.getDataOfPax(pax, "relation_ship") === null) {
          this.documentPrimary = pax;
          this.countryPrimary = this.getDataOfPax(pax, "country_id");
          this.emailPrimary = this.getDataOfPax(pax, "email");
        }
        if (pax === null) {
          document = this.documentPrimary;
        }
        if (country_id === null) {
          country_id = this.countryPrimary;
        }
        if (email === "undefined" || email === null) {
          email = this.emailPrimary;
        }
        if (type === "children") {
          this.paxes = this.paxes.filter((c) => Number(c.document_number) !== Number(pax));
        }
        if (type !== "children") {
          this.paxAdultsArr = this.paxAdultsArr.filter((c) => Number(c.document_number) !== Number(pax));
          this.paxes = this.paxes.filter((c) => Number(c.document_number) !== Number(pax));
        }

        this.paxesInItems.push({
          idPax: this.getRandom(),
          itemId: item.id,
          clientId: clientId ? clientId : null,
          document_number: document,
          first_name: firstName,
          last_name: lastName,
          email: email,
          type: type === "children" ? "CH" : "AD",
          country_id: country_id,
          birth_date: birth_date,
          title: title,
          identificationType: identificationType,
          phone: phone,
          phone2: "",
          address: address,
          city: city,
          state_id: state_id,
          postal_code: "6301",
          idUnique: uid,
          gender: gender,
          relation_ship_catalogue_id: Number(relation_ship),
          holder: "true",
        });

        this.paxesForItems.emit(this.paxesInItems);
        this._permitedReservation();
      }
      //this.paxesInItems

      console.log("this.paxesInItems ", this.paxesInItems);
    }
    // this.selectedItem = '';
    event.target.value = "";
    this.selectNull();

  }

  requestHotel(pax: any) {
    console.log(pax);
  }

  setTransportToItem(trans) {
    this.transportLabel = this.transport.find((e) => e.value === trans).label;
    this.transportEx = this.transport.find((e) => e.value === trans).placeholder;
    trans == "cruise" ? (this.isCruise = true) : (this.isCruise = false);
  }

  getDataOfPax(pax: number, name: string) {
    console.log(pax);
    // Verificar si pax es una cadena
    if (typeof pax == "string") {
      // Convertir pax a número si es una cadena
      pax = Number(pax);
    }

    const index = this.paxes.findIndex((val) => val.document_number == pax);

    if (name === "name") {
      return this.paxes[index].first_name;
    }
    if (name === "last_name") {
      return this.paxes[index].last_name;
    }
    if (name === "email") {
      return this.paxes[index].email;
    }
    if (name === "country_id") {
      return this.paxes[index].country_id;
    }
    if (name === "relation_ship") {
      return this.paxes[index].relation_ship_catalogue_id;
    }
    if (name === "idUnique") {
      return this.paxes[index].idUnique;
    }
  }

  _permitedReservation() {
    let permited = true;
    this.permitedReservation = false;
    this.itineraryItems.forEach((item) => {
      if (this.checkProductConfirm(item) === false) {
        permited = false;
      }
    });

    this.permitedReservation = permited;
  }

  checkProductConfirm(item: any): boolean {
    const paxesFilter = this.paxesInItems.filter((val) => val.itemId === item.id);
    const overCapacity = paxesFilter.length > item.paxAdults + item.paxChildren;
    if (item.categoryName === "RENT-A-CAR") {
      // tslint:disable-next-line:max-line-length   additionalDriver
      if (item?.additionalDriver) {
        return paxesFilter.length === item.paxAdults + 1 + item.paxChildren ? true : false;
      } else {
        return paxesFilter.length === item.paxAdults + item.paxChildren ? true : false;
      }
    }
    // tslint:disable-next-line:max-line-length
    if (item.categoryName === "HOTELS" || item.categoryName === "INSURANCE" || item.categoryName === "CRUISES") {
      return paxesFilter.length === item.paxAdults + item.paxChildren ? true : false;
    }
    // tslint:disable-next-line:max-line-length
    if (item.categoryName === "TOURS") {
      // tslint:disable-next-line:max-line-length
      this.element_ = document.getElementById("hotel-" + item.id) as HTMLElement;
      if (this.element_) {
        // tslint:disable-next-line:max-line-length
        if ((document.getElementById("hotel-" + item.id) as HTMLInputElement).value === "" || !this.checkPaxConfirm(item)) {
          return false;
        }
      }
      return paxesFilter.length >= 1 ? true : false;
    }
    // tslint:disable-next-line:max-line-length
    if (item.categoryName === "DISNEY" || item.categoryName === "PTHEMES") {
      return paxesFilter.length >= 1 ? true : false;
    }

    if (item.categoryName === "TRANSPORTS") {
      if (
        (document.getElementById("transport-" + item.id) != null &&
          (document.getElementById("transport-" + item.id) as HTMLInputElement).value === "") ||
        (this._form.get("arrivalTime") != null && this._form.get("arrivalTime").value == null) ||
        (document.getElementById("idTransport-" + item.id) != null &&
          (document.getElementById("idTransport-" + item.id) as HTMLInputElement).value === "") ||
        (document.getElementById("transport-" + item.id) != null &&
          (document.getElementById("transport-" + item.id) as HTMLInputElement).value === "cruise" &&
          (document.getElementById("cruiseNumber-" + item.id) as HTMLInputElement).value === "")
      ) {
        return false;
      } else {
        return paxesFilter.length >= 1 ? true : false;
      }
    }
  }

  checkPaxConfirm(item: any): boolean {
    let verif = false;
    const paxesFilter = this.paxesInItems.filter((val) => val.itemId === item.id);
    if (item.categoryName === "TOURS") {
      for (let i = 0; i < paxesFilter.length; i++) {
        this.element = document.getElementById("document_number-" + paxesFilter[i].idPax) as HTMLElement;

        if (this.element) {
          const document_number = (document.getElementById("document_number-" + paxesFilter[i].idPax) as HTMLInputElement).value;
          const email = (document.getElementById("email-" + paxesFilter[i].idPax) as HTMLInputElement).value;
          const country_id = (document.getElementById("country_id-" + paxesFilter[i].idPax) as HTMLInputElement).value;
          if (document_number === "" || email === "" || country_id === "") {
            return false;
          } else {
            verif = true;
          }
        } else {
          verif = true;
        }
      }
      return verif === true ? true : false;
    }
  }

  checkQtyPaxesByCategory(item: any): boolean {
    let paxes = 0;
    // console.log("item", item);
    this.hotelTitleSelect = item.titleHotel;

    if (item.categoryName == "RENT-A-CAR") {
      if (item?.additionalDriver) {
        paxes = item.paxAdults + 1 + item.paxChildren;
      } else {
        paxes = item.paxAdults + item.paxChildren;
      }
    } else {
      paxes = item.paxAdults + item.paxChildren;
    }

    let qtyPaxesItem = 0;
    this.paxesInItems.forEach((pax) => {
      if (pax.itemId == item.id) {
        qtyPaxesItem += 1;
      }
    });

    if (qtyPaxesItem <= paxes) {
      return true;
    }

    return false;
  }

  removePax(pax, item, paxData) {
    if (paxData.type === "AD") {
      const pax = this.paxesOriginal.find((c) => c.document_number === Number(paxData.document_number));
      const index = this.paxAdultsArr.findIndex((c) => c.document_number === Number(paxData.document_number));
      if (index === -1) {
        this.paxes.push(pax);
        this.paxAdultsArr.push(pax);
        this.selectNull();
      }
    }
    if (paxData.type === "CH") {
      const pax = this.paxesOriginal.find((c) => c.document_number === Number(paxData.document_number));
      const index = this.paxes.findIndex((c) => c.document_number === Number(paxData.document_number));
      if (index === -1) {
        this.paxes.push(pax);
        this.selectNull();
      }
    }
    let index = this.paxesInItems.findIndex((val) => val.itemId == item && val.idUnique == pax);
    this.paxesInItems.splice(index, 1);
    this.paxesForItems.emit(this.paxesInItems);
    this._permitedReservation();
  }

  additionalDriver(pax: any, idPax: any) {
    // console.log("this.paxesInItems antes", this.paxesInItems);
    // console.log("pax", pax);
    // console.log("item", idPax);

    // let index = this.paxesInItems.findIndex((val) => val.itemId == item && val.document_number == pax);
    // console.log("index", index);

    for (let i = 0; i < this.paxesInItems.length; i++) {
      this.paxesInItems[i].holder = "false";
      if (this.paxesInItems[i].idPax === idPax) {
        this.paxesInItems[i].holder = "true";
      }
    }
  }

  shouldContinue(event: boolean) {
    this.shouldContinueResolver(event); // Resuelve la promesa con el valor booleano
  }

  _return() {
    this.userService.stepReservation = "two";
  }

  getPaxesInfo(paxes: any): any {
    let paxesResult = [];
    paxes.forEach((paxFilter) => {
      let pax = paxes.find((val) => val.idUnique == paxFilter.idUnique);
      paxesResult.push(pax);
    });
    // this.ngxSpinner.hide();
    return paxesResult;
  }
  public hotelArr: any[] = [];
  categorys = {
    HOTELS: [
      {
        product: [],
      },
    ],
    RENT_A_CAR: [
      {
        product: [],
      },
    ],
    TOURS: [
      {
        product: [],
      },
    ],
  };

  _payload(item: any, paxes: any): any {


    console.log("payload item", item);

    let payload: any;
    for (let i = 0; i < item.length; i++) {
      const productId = item[i].categoryName == "INSURANCE" ? item[i].title : item[i].categoryName == "DISNEY" ? item[i].id : item[i].id;
      const searchId = item[i].categoryName == "DISNEY" ? item[i].arrayResults[0]["searchId"] : item[i].searchId;
      const providerId = item[i].categoryName == "DISNEY" ? item[i].arrayResults[0]["providerId"] : item[i].providerId;
console.log(item)
      if (item[i].categoryName == "HOTELS") {
        // for(let i = 0; i > paxes.length; i++) {
        //   paxes.request =
        // }

        payload = {
          id: item[i].id,
          searchId: item[i].searchId,
          providerId: item[i].providerId,
          providerName: item[i].providerName,
          title: item[i].title,
          description: item[i].description,
          categoryId: item[i].category_id,

          productId: item[i].idProduct,
          channelId: this.userService._channel_id,
          clientId: this.userService._agencyID,
          clientName: this.userService._agencyName,
          agencyName: this.userService._agencyName,
          agencyId: this.userService._licenseId,
          // tslint:disable-next-line:max-line-length
          origin:
            item[i].categoryName != "DISNEY"
              ? item[i].payloadUpdate.products[0].origin
                ? item[i].payloadUpdate.products[0].origin
                : null
              : null,
          // tslint:disable-next-line:max-line-length
          destination:
            item[i].categoryName != "DISNEY"
              ? item[i].payloadUpdate.products[0].destination
                ? item[i].payloadUpdate.products[0].destination
                : "KOA"
              : item[i].destination.iata_code,
          // tslint:disable-next-line:max-line-length
          // nationality: this.formData.nationality,
          nationality: item[i].payloadUpdate.products[0].nationality,
          dateIni:
            item[i].categoryName == "DISNEY"
              ? new DatePipe("en-US").transform(item[i].destination.destination_info.arrival, "yyyy-MM-dd")
              : item[i].arrivalDate,
          // tslint:disable-next-line:max-line-length
          dateEnd:
            item[i].categoryName == "DISNEY"
              ? new DatePipe("en-US").transform(item[i].destination.destination_info.departure, "yyyy-MM-dd")
              : item[i].departureDate,
          nameAdapter: item[i].categoryName != "DISNEY" ? item[i].payloadUpdate.products[0].nameAdapter : "Majestic_Disney",
          additionalInfo: this.setAdditionalInfo(item[i], paxes),
        };
        console.log(payload);
        console.log("se envio a reservar: ", "hotel");
        // categorys.HOTELS = payload;
        this.categorys.HOTELS[0].product.push(payload);
        // console.log(categorys);
        // this.makeReserve(categorys.HOTELS, "HOTELS", environment.hotels + "/reservation");
      }

      if (item[i].categoryName == "TOURS") {
        console.log("Estoy en payload de tours");
        payload = {
          id: item[i].id,
          searchId: searchId,
          providerId: providerId,
          // providerName: item[i].providerName,
          title: item[i].title,
          description: item[i].description,
          categoryId: item[i].category_id,
          productId: productId,
          destination: item[i].destination.iata_code,
          dateIni: item[i].arrivalDate,
          dateEnd: item[i].departureDate,
          //nameAdapter: "Provider_Adapter",
          additionalInfo: this.setAdditionalInfo(item[i], paxes),
        };

        console.log(payload);
        console.log("se envio a reservar: ", "tours");
        console.log(this.categorys);
        this.categorys.TOURS[0].product.push(payload);
        console.log(this.categorys);
        // this.makeReserve(categorys.TOURS, "TOURS", environment.tours + "/reservation");
      }
      console.log('searchId Reserva ->', searchId);

      if (item[i].categoryName == "RENT-A-CAR") {
        payload = {
          searchId: item[i].searchId, //searchId
          id: item[i].id,
          providerId: providerId,
          categoryId: "rentacar", //item.category_id,
          productId: productId,
          pickup: item[i].destination.iata_code,
          dropoff: item[i].destination.iata_code,
          dateIni: item[i].arrivalDate,
          dateEnd: item[i].departureDate,
          codereference: item[i].rentacarId,
          BookingCode: item[i].RatePlanCode, // item?.syncProduct?.bookParam, //
          Comment: this.commentsRentacar,
          additionalInfo: this.setAdditionalInfo(item[i], paxes),
        };

        console.log(payload);
        console.log("se envio a reservar: ", "RENT-A-CAR");
        console.log(this.categorys);
       this. categorys.RENT_A_CAR[0].product.push(payload);
        console.log(this.categorys);
        // this.makeReserve(categorys.RENT_A_CAR, "RENT-A-CAR", environment.rentacar + "/reservation");
      }
    }

    console.log(JSON.stringify(this.categorys));
    if (this.categorys.HOTELS[0].product.length > 0) {
      this.makeReserve(this.categorys.HOTELS[0], "HOTELS", environment.hotels + "/reservation");
    }
    if (this.categorys.TOURS[0].product.length > 0) {
      this.makeReserve(this.categorys.TOURS[0], "TOURS", environment.tours + "/reservation");
    }
    if (this.categorys.RENT_A_CAR[0].product.length > 0) {
      this.makeReserve(this.categorys.RENT_A_CAR[0], "RENT-A-CAR", environment.rentacar + "/reservation");
    }

    this.categorys = {
      HOTELS: [],
      RENT_A_CAR: [],
      TOURS: [],
    };
    // return payload;
  }

  /// versión en Lote de reservas Yovanny 01/01/2024
  async _onSubmit() {
    this.results = true;

    let categorys = {
      HOTELS: [],
      RENT_A_CAR: [],
      TOURS: [],
    };

    this.itemsSync = this.itinerary.items.filter((object) => {
      return object["status_id"] == 1;
    });
    console.log(this.itemsSync[0].itinerary_id);
    this.itineraryId = this.itemsSync[0].itinerary_id;
    await this.itineraryDetail.updateCards(true);
    const shouldContinueValue = await this.shouldContinuePromise;
    let contadodrItemsFor: number = 0;
    this.appLoading = true;
    if (!shouldContinueValue) {
      let index = 0;
      this.itineraryItems.forEach((item) => {
        console.log("item ->", item);
        contadodrItemsFor++;
        const paxesFilter = this.paxesInItems.filter((val) => val.itemId == item.id);
        let paxesData = this.getPaxesInfo(paxesFilter);
        this.paxesArr.push(paxesData);
        this.categoryName = item.categoryName;
      });
      console.log("item a reserva,", this.itemsNewArr);
        switch (this.categoryName) {
          case "HOTELS":
            console.log(this.paxesArr);
            console.log(this.itemsNewArr);
            this._payload(this.itemsNewArr, this.paxesArr);
            break;
          case "RENT-A-CAR":
            console.log("entre a rentacar");
            console.log(this.itemsNewArr);
            this._payload(this.itemsNewArr, this.paxesArr);
            break;
          case "TOURS":
            console.log("entre a tours");
            this._payload(this.itemsNewArr, this.paxesArr);

            break;
        }
      index++;

      //recorreer las categorias
      //  for (let index = 0; index < 3; index++) {
      // x|AS
      //  }
      return true;
    }
  }
  index: number = 0;

  makeReserve(payload: any, categoryName: any, url: any) {
    try {
      let response;
      console.log(payload);
      this._restService.doPost("api", url, payload).subscribe(
        (data: any) => {
          response = data;
          console.log("this.response reservation", response);
          console.log(JSON.stringify(response))
          if (response) {
            response.forEach((item, index) => {

              //se hizo la reserva
              if (item.code_status == "AV368") {
                if (localStorage.getItem("nrPayment")) {

                  response.result.currency = this.userService?.itineraryActive[0]?.currency;
                  this.postMainTransaction(response.result);
                }
                switch (categoryName) {
                  case "HOTELS":

                    let roomData: any;
                    let roomDataResult: any;
                    let voucherInfo;
                    let additionalInfo
                    if (payload.product.length > 0) {
                      if (payload.product.length === 1) {
                        roomData = payload.product[0].additionalInfo.rooms[index];
                        additionalInfo = payload.product[0].additionalInfo
                        voucherInfo = payload.product[0].additionalInfo.voucherData;
                      } else {
                        roomData = payload.product[index].additionalInfo.rooms;
                        additionalInfo = payload.product[index].additionalInfo;
                        voucherInfo = payload.product[index].additionalInfo.voucherData;
                      }
                      roomDataResult = roomData.paxList;
                    } else {
                      roomData = payload.product[0].additionalInfo.rooms;
                      additionalInfo = payload.product[0].additionalInfo;
                      roomDataResult = roomData[index].paxList;
                      voucherInfo = payload.product[0].additionalInfo.voucherData;
                    }
                    // item.result.additionalInfo.reservationDetail[0].pax[0] = roomDataResult;

                    console.log("item a guardar booking", item);
                    // item.result.forEach((data: any, index: number) => {

                      this.resultReserve.push({
                        status_id: 2,
                        itinerary_id: this.itineraryId, // nuevo
                        starDate: moment(), // nuevo
                        endDate: moment(), // nuevo
                        resultReserve: item.result,
                        category_id: item.result?.categoryId,
                        providerId:
                         data.categoryName === "DISNEY" ? data.arrayResults[0]["providerId"] : item.result.providerId,
                        netPrice:  item.result.reservationDetails?.rooms[0]?.netPrice, // payload.product[index]?.additionalInfo.rooms[0].netPriceTax, // item.result.optionNightsNetTotal,    // ponerlos a partir del payload y no desde el resultado
                        // totalPrice: item.result.totalPrice, // item.result.optionNightsNetTotalProf,
                        totalPrice: item.result?.reservationDetails?.rooms[0]?.totalPrice, // item.result.optionNightsNetTotalProf,    // ponerlos a partir del payload y no desde el resultado
                          // paxList: this.setPaxesReserve(paxes, "HOTELS"),
                        paxList: roomData.paxList,
                        id: item.result?.reservationDetails?.rooms[0]?.id, // ¿este id? es roomId o que? u hotelReservationId
                          // id: item.result.additionalInfo.reservationDetail[0].roomId,
                        // percentage: data.percentage,
                      });                    // })
                    this.resultReserve[index].resultReserve.request = roomData.request;
                    this.resultReserve[index].resultReserve.comments = roomData.comments;
                    this.resultReserve[index].resultReserve.additionalInfo = additionalInfo;
                    this.resultReserve[index].resultReserve.voucherInfo = voucherInfo;
                    break;
                  case "TOURS":
                    console.log("response status", response[0].status);
                    this.resultReserve.push({
                      itinerary_id: this.itineraryId,
                      status_id: 2,
                      // response.status === true ? 2 : 0
                      starDate: moment(),
                      endDate: moment(),
                      resultReserve: item.result,
                      category_id: item.result.categoryId,
                      providerId: item.categoryName === "DISNEY" ? item.arrayResults[0]["providerId"] : item.result.providerId,
                      netPrice: this.itineraryItems[index].netPriceAgent,
                      totalPrice: this.itineraryItems[index].netPriceBooking,
                      paxList: this.setPaxesReserve(this.paxesArr, "TOURS"),
                      id: item.result.additionalInfo.reservationDetail[0].tourId,
                      // percentage: item.percentage,
                    });
                    // const paxesInforResultTours =  this.resultReserve[0].resultReserve.additionalInfo.reservationDetailTours[0].pax[0];
                    // this.resultReserve[0].resultReserve.additionalInfo.reservationDetailTours[0].pax[0] = this.clientIdPaxes(paxesInforResultTours, this.paxesArr)
                    break;
                  case "RENT-A-CAR":
                    let state_id: any = 0;

                    switch (response.result?.additionalInfo?.statusProvider) {
                      case "Confirmed":
                        state_id = 2;
                        break;
                      case "Cancelled":
                        state_id = 5;
                        break;
                      case "PaidOut":
                        state_id = 2;
                        break;
                      case "Confirmed - (Pending credit card charge)":
                        state_id = 2;
                        break;
                      case "OnRequest":
                        state_id = 2;
                        break;
                    }

                    console.log("item rentacar", item);
                    this.resultReserve.push({
                      itinerary_id: this.itineraryId,
                      status_id: 2,
                      resultReserve: item.result, //dentro de results se encuentra el idReservation para rentacar
                      category_id: item.result.categoryId,
                      providerId: item.result.providerId,
                      netPrice: item.result.additionalInfo.totalPrice,
                      totalPrice: item.result.additionalInfo.Priceservice,
                      paxList: this.setPaxesReserve(this.paxesArr[index], "RENT-A-CAR"),
                      id: item.result.additionalInfo.reservationDetailCarRental.rentcarId, //RentcarId se necesita para cambiar el status a reservado.
                      // percentage: item.percentage,
                    });
                    break;
                }
                console.log("this.resultReserve.length", this.resultReserve.length);
                console.log("this.itineraryItems.length", this.itineraryItems.length);
                console.log("this.resultReserve.length", this.resultReserve);
                console.log("this.itineraryItems.length", this.itineraryItems);
              }else{
              //Ocurrio un problema en el proveedor
                console.log("GUARDA EN EL ERROR");
                this.appLoading = false;
                var dialogRef = this.dialog.open(MaterialModalAlertComponent, {
                  height: "auto",
                  width: "350px",
                  data: item.message,
                  disableClose: true,
                });
                if (this.resultReserve.length - 1 >= this.itineraryItems.length) {
                } else {
                  this.appLoading = false;
                }

                this.categorys = {
                  HOTELS: [],
                  RENT_A_CAR: [],
                  TOURS: [],
                };
              }
            });

            if (this.resultReserve && this.resultReserve.length > 0) {
              if (categoryName === "HOTELS") {
                console.log(this.resultReserve)
                // console.log(this.itemsNewArr)
                // console.log(this.resultReserve)
                // if (this.resultReserve.length >= this.itemsNewArr.length) {
                //   let roomData: any[] = []; // Asegúrate de que roomData es un array

                //   if (payload.product.length > 0) {
                //     payload.product.forEach((product: any) => {
                //       if (product.additionalInfo && product.additionalInfo.rooms && product.additionalInfo.rooms.length > 0) {
                //         console.log(product.additionalInfo.rooms);
                //         console.log(product.additionalInfo.rooms[0] );
                //         roomData.push(product.additionalInfo.rooms[0]);
                //       } else {
                //         console.error("product.additionalInfo.rooms no está definido o está vacío");
                //       }
                //     });
                //   } else {
                //     if (payload.product[0] && payload.product[0].additionalInfo && payload.product[0].additionalInfo.rooms) {
                //       roomData = payload.product[0].additionalInfo.rooms;
                //     } else {
                //       console.error("payload.product[0].additionalInfo.rooms no está definido");
                //     }
                //   }
                //   console.log(roomData)
                //   console.log(this.resultReserve)
                //   roomData.forEach((rooms: any, index: number) => {
                //     this.resultReserve[index].resultReserve.additionalInfo.reservationDetail[0].pax = [];
                //     this.resultReserve[index].resultReserve.additionalInfo.reservationDetail[0].pax = rooms.paxList;
                //   });
                  console.log("save reservation");
                  this.saveReservation(this.resultReserve);
                  this.appLoading = false;
                // }
              }

              if (categoryName === "TOURS") {
                this.saveReservation(this.resultReserve);
                this.appLoading = false;
              }

              if (categoryName === "RENT-A-CAR") {
                console.log("entro a rent-a-car para el servicio de book-api", this.resultReserve);
                this.saveReservation(this.resultReserve);
                this.appLoading = false;
              }
            }
          }
        },
        (error) => {
          console.log("GUARDA EN EL ERROR");
          this.appLoading = false;
          this.categorys = {
            HOTELS: [],
            RENT_A_CAR: [],
            TOURS: [],
          };
          var dialogRef = this.dialog.open(MaterialModalAlertComponent, {
            height: "auto",
            width: "350px",
            data: "Ocurrio un problema al realizar la reserva",
            disableClose: true,
          });
          if (this.resultReserve.length - 1 >= this.itineraryItems.length) {
          } else {
            this.appLoading = false;
          }
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
      this.categorys = {
        HOTELS: [],
        RENT_A_CAR: [],
        TOURS: [],
      };
      this.appLoading = false;
      setTimeout(() => {
        this.dialog.closeAll();
      }, 1500);
    }
  }

  saveReservation(reserve: any) {
    console.log(" ------  GUARDA LA RESERVACION ---- ", reserve);
    reserve["itinerario_id"] = this.itineraryId;
    this._restService.doPost("app", "reservations", reserve).subscribe(
      (response: any) => {
        console.log("response reservations local", response);
        this.confirmationRedirect(response.data.reservation_id);
      },
      (error) => {
        this.categorys = {
          HOTELS: [],
          RENT_A_CAR: [],
          TOURS: [],
        };
        console.log("error en API: ", error);
      }
    );
  }

  clientIdPaxes(paxesResult: any, paxesValue: any): number {
    console.log("inicio");
    console.log("paxesResul : ", paxesResult);
    console.log("paxesValue: ", paxesValue);
    let paxDataValues = paxesValue;
    let value: number = null;
    paxDataValues = paxDataValues.forEach((paxes: any, index: number) => {
      console.log(paxes);
      const documentPaxes = Number(paxes.document_number);
      const documentPaxesResult = Number(paxesResult[index].identification);

      if (documentPaxes === documentPaxesResult) {
        value = Number(paxes.clientId);

        paxesResult = paxesResult.map((p: any) => {
          //comentado  yovanny 04-052-024
          console.log(p);
          p.clientId = Number(value) > 0 ? Number(value) : null;
          return p;
        });
      }
      return value;
    });
    console.log(paxesResult);
    return paxesResult;
  }

  setAdditionalInfo(item: any, paxes: any): any {
    console.log(item)
    let additionalInfo;
    console.log("item setAdditionalInfo");
    switch (item.categoryName) {
      case "HOTELS":
        additionalInfo = {
          voucherData: this._voucherData(item),
          hotelId: item.room.hotelCode,
          roomQty: 1,
          arrival: item.arrivalDate,
          departure: item.departureDate,
          rooms: this._rooms(item, paxes),
        };

        break;
      case "TOURS":
        console.log("item tours", item);
        additionalInfo = {
          tours: [
            {
              serialized: false,
              company: item.company,
              optionId: item.optionId,
              paxAdults: item.paxAdults,
              paxChildren: item.paxChildren,
              operationDateFrom: item.selectDay,
              operationDateTo: item.selectDay,
              iataCode: item.iata_code,
              bookingCode: item.bookingCode ? item.bookingCode : null,
              rateKey: item.bookingCode ? item.bookingCode : null,
              paxList: this.setPaxesReserve(this.paxesInItems, "TOURS"),
              // answers: [
              //   {
              question: this._questionsPayload(item, paxes),
              // {
              //   code: item.questions[0].code,
              //   text: this.namehotel,
              //   required: item.questions[0].required,
              // },
              // answer: "mailto:gilovalles@gmail.com",
              //   },
              // ], //item.questions.length > 0 ? [] : [],
              // sessionCode: "MORNING", //item.questions.length > 0 ? [] : null,
              // languageCode: "es",
            },
          ],
        };

        break;
      case "TRANSPORTS":
        additionalInfo = {
          bookParam: item.bookParam,
          transfers: [
            {
              oneWay: item.payload.others.transfers.oneWay,
              contractList: item.contractList,
              pickupTime: item.pickupTime,
              dropoffTime: item.dropoffTime,
              pickupLocation: {
                id: item.payload.others.transfers.pickupLocation.id,
                type: item.payload.others.transfers.pickupLocation.type,
              },
              dropoffLocation: {
                id: item.payload.others.transfers.dropoffLocation.id,
                type: item.payload.others.transfers.dropoffLocation.type,
              },
              goingWay: {
                transfersTypeLeg: item.vehicles[0].transfersTypeLeg ? item.vehicles[0].transfersTypeLeg : "IN",
                transferCode: item.vehicles[0].transferCode ? item.vehicles[0].transferCode : "0|0|0",
                transferType: item.vehicles[0].transferType ? item.vehicles[0].transferType : "P",
                vehicleType: item.vehicles[0].transferType ? item.vehicles[0].transferType : 9,
                travelInfo: "xyz",
                travelCompanyName: "avior airline",
                travelDate: item.pickupTime,
                travelTime: item.pickupTime,
              },
              returnWay: {
                transfersTypeLeg: item.vehicles[1].transfersTypeLeg ? item.vehicles[1].transfersTypeLeg : "OUT",
                transferCode: item.vehicles[1].transferCode ? item.vehicles[1].transferCode : "1|0|0",
                transferType: item.vehicles[1].transferType ? item.vehicles[1].transferType : "P",
                vehicleType: item.vehicles[1].transferType ? item.vehicles[1].transferType : 9,
                travelInfo: "abc",
                travelCompanyName: "avianca",
                travelDate: item.dropoffTime,
                travelTime: item.dropoffTime,
              },
              paxList: this.setPaxesReserve(paxes, "TRANSPORTS"),
              transport: (document.getElementById("transport-" + item.id) as HTMLInputElement).value,
              idTransport: (document.getElementById("idTransport-" + item.id) as HTMLInputElement).value,
              arrivalTime: moment(this._form.get("arrivalTime").toString().split(",")[0]).format("HH:mm"),
              cruiseNumber:
                document.getElementById("cruiseNumber-" + item.id) != null
                  ? (document.getElementById("cruiseNumber-" + item.id) as HTMLInputElement).value
                  : "",
              netPrice: item.payload.others.transfers.totalPrice,
              /*   totalPrice: item.payload.others.transfers.totalPriceProf,
                 percentage: item.payload.others.transfers.percentage */
            },
          ],
        };
        break;
      case "INSURANCE":
        additionalInfo = {
          insurance: {
            price: item.totalPrice,
            /*  totalPrice: item.totalPriceProf,
              percentage: item.percentage, */
            paxList: this.setPaxesReserve(paxes, "INSURANCE"),
          },
        };
        break;
      case "PTHEMES":
        additionalInfo = {
          arrival: item.arrivalDate,
          departure: item.departureDate,
          paxList: {
            pax: this.setPaxesReserve(paxes, "PTHEMES"),
          },
          optionId: item.optionId,
          price: item.priceAdults,
          /*totalPrice: item.priceAdultsProf,
          percentage: item.percentage*/
        };
        break;
      case "DISNEY":
        additionalInfo = {
          searchId: item.hotel["additionalInfo"]["searchId"],
          hotelId: item.hotel.id,
          roomId: item.room.roomId,
          accId: item.room.accomodationId,
          rateId: item.plan.rateId,
          ticketTypeId: item.ticket !== undefined ? item.ticket.id : 0,
          mealPlanId: item.mealPlan !== null ? item.mealPlan.id : 0,
          arrivalDate: item.destination.destination_info.arrival,
          departureDate: item.destination.destination_info.departure,
          days: item.days,
          noExpiration: false,
          paxList: this.setPaxesReserve(paxes, "DISNEY"),
          price: item.room.netPrice,
          /* totalPrice: item.room.netPriceProf,
           percentage: item.room.percentage */
        };
        break;
      case "RENT-A-CAR":
        additionalInfo = {
          carRental: [
            {
              pickupTime: item.payloadUpdate.products[0].others.carRental.pickupLocation.pickupTime,
              dropoffTime: item.payloadUpdate.products[0].others.carRental.dropoffLocation.dropoffTime,
              pickupLocationId: item.pickupLocationId,
              dropoffLocationId: item.dropoffLocationId,
              FlightTime: "0001-01-01T00:00:00",
              FlightNumber: "XX1234",
              paxes: this.setPaxesReserve(this.paxesInItems, "RENT-A-CAR"),
            },
          ],
        };
        break;
      default:
        break;
    }
    console.log(additionalInfo);
    return additionalInfo;
  }

  private _voucherData(item: any): any {
    console.log(item);
    return {
      hotelAdditionalInfo: {
        timeInformation: {
          checkIn: item?.hotelAdditionalInfo?.TimeInformation?.checkIn,
          checkOut: item?.hotelAdditionalInfo?.TimeInformation?.checkOut,
        },
        coordinates: {
          latitude: item?.hotelAdditionalInfo?.coordinates?.latitude,
          longitude: item?.hotelAdditionalInfo?.coordinates?.longitude,
        },
        city: item?.hotelAdditionalInfo?.city,
        country: item?.hotelAdditionalInfo?.country,
        starRating: item?.hotelAdditionalInfo?.starRanting,
        zipCode: item?.hotelAdditionalInfo?.zipCode,
        location: item?.hotelAdditionalInfo?.location,
      },
      form: {
        qtyAdults: item?.form?.room?.qtyAdults,
        qtyChilds: item?.form?.room?.qtyChilds,
        childsAge: item?.form?.room?.childsage,
      },
      roomType: item?.roomType,
      boardname: item?.roomsData[0]?.boardName,
      cancellation_policy: item?.roomsData[0]?.cancellation_policy,
      description: item?.roomsData[0]?.description,
    };
  }

  private _rooms(item: any, paxes: any): any[] {
    console.log("items Rooms: ", item);
    console.log("paxes: ", paxes);
    let rooms: any[] = [];

    // Itera sobre todos los elementos de roomsData para unirlos en un solo array
    for (let i = 0; i < item.roomsData.length; i++) {
      rooms.push({
        id: item.roomsData[i].id ? item.roomsData[i].id : null,
        bookParam: item.roomsData[i].bookingCode ? "'" + item.roomsData[i].bookingCode + "'" : null,
        roomId: item.roomsData[i].roomId ? item.roomsData[i].roomId : null,
        optionId: item.roomsData[i].optionId ? item.roomsData[i].optionId : null,
        accomodationId: item.roomsData[i].accomodationId ? item.roomsData[i].accomodationId : null,
        maxPax: 1,
        tax: item.roomsData[i].tax ? item.roomsData[i].tax : null,
        netPrice: item.roomsData[i].netPriceFreeTax,
        netTotalPrice: item.roomsData[i].netPriceProf,
        totalNetPrice: item.roomsData[i].optionNightsNetTotalFreeTax,
        totalPrice: item.roomsData[i].optionNightsNetTotalProf,
        currency: item.room?.pricing?.currency,
        percentage: item.roomsData[i].percentage ? item.roomsData[i].percentage : null,
        paxList: this.setPaxesReserve(paxes, "HOTELS", item.roomsData[i].id, item.roomsData[i]?.form?.nationality),
        request: item.roomsData[i].remarksSelect ? item.roomsData[i].remarksSelect : this.initializeRemarksSelect(),
        comments: item.roomsData[i].comments ? item.roomsData[i].comments : "",
      });
    }

    console.log("rooms: ", rooms);
    return rooms;
  }

  requestCommentHotel(item?: any, value?: any) {
    this.commentHotels = value;

    const index = this.itineraryItems.findIndex((c) => c.productKey === item.productKey);

    if (index !== -1) {
      const itemSelected = this.itineraryItems[index];
      console.log(itemSelected);

      // Inicializa remarksSelect si aún no se ha hecho
      if (!itemSelected.remarksSelect) {
        itemSelected.comments = "";
      }

      if(item.categoryName === 'RENT-A-CAR'){
        this.commentsRentacar = value;
      }

      itemSelected.comments = value;
    }
  }

  initializeRemarksSelect() {
    return {
      doublebed: false,
      adjoiningRooms: false,
      clientWillBelate: false,
      twoBeds: false,
    };
  }

  requestRoomHotel(item?: any, remark?: any): any {
    console.log(item);
    console.log(remark);

    const index = this.itineraryItems.findIndex((c) => c.productKey === item.productKey);
    console.log(index);
    if (index !== -1) {
      const itemSelected = this.itineraryItems[index];
      console.log(itemSelected);

      // Inicializa remarksSelect si aún no se ha hecho
      if (!itemSelected.remarksSelect) {
        itemSelected.remarksSelect = this.initializeRemarksSelect();
      }

      switch (remark) {
        case "Cama matrimonial":
          itemSelected.remarksSelect.doublebed = !itemSelected.remarksSelect.doublebed;
          break;
        case "Habitaciones contiguas":
          itemSelected.remarksSelect.adjoiningRooms = !itemSelected.remarksSelect.adjoiningRooms;
          break;
        case "Cliente llegará tarde":
          itemSelected.remarksSelect.clientWillBelate = !itemSelected.remarksSelect.clientWillBelate;
          break;
        case "Dos camas":
          itemSelected.remarksSelect.twoBeds = !itemSelected.remarksSelect.twoBeds;
          break;
      }

      console.log(itemSelected.remarksSelect);
      console.log(this.itineraryItems);
    }
  }

  setPaxesReserve(pax: any, category: any, itemId?: any, nationality?: string): any {
    console.log("paxes reserve ->", pax);
    console.log(itemId);
    const paxLength: number = pax.length;
    let paxesAux = [];
    let paxes = [];
    let n = 1;

    if (pax !== undefined) {
      // Itera sobre cada array en pax
      pax.forEach((subArray) => {
        console.log(subArray);
        if (Array.isArray(subArray)) {
          // Filtra los elementos cuyo itemId está en la lista proporcionada
          const filteredItems = subArray.filter((item) => itemId == item.itemId);

          // Agrega los elementos filtrados a paxesAux
          paxes.push(...filteredItems);
          console.log("paxes reserve", paxes);
        }
      });
    }
    console.log(paxes);
    if (paxes.length > 0 && itemId !== undefined) {
      for (let i = 0; i < paxes.length; i++) {
        const birth_date = moment(paxes[i].birth_date, ["YYYY-DD-MM", "DD-MM-YYYY", "YYYY-MM-DD", "MM-DD-YYYY"]).format("YYYY-MM-DD");
        const Age = moment().diff(birth_date, "years");
        console.log(paxes[i]);
        switch (category) {
          case "HOTELS":
            paxesAux.push({
              clientId: paxes[i].clientId,
              gender: paxes[i].gender,
              title: paxes[i].title,
              firstName: paxes[i].first_name,
              lastName: paxes[i].last_name,
              identificationType: paxes[i].identificationType,
              identification: paxes[i].document_number,
              type: paxes[i].type === "AD" ? "AD" : "CH",
              birth_date: birth_date,
              age: Age,
              email: paxes[i].email,
              phone: paxes[i].phone,
              address: paxes[i].address,
              nationality: nationality ? nationality : "AR",
              country: paxes[i].country_id,
              city: paxes[i].city,
              idUnique: paxes[i].idUnique,
              state: paxes[i].state_id,
              relation_ship_catalogue_id: paxes[i].relation_ship_catalogue_id,
              holder: i == 0 ? true : false,
            });
            n = i + 1;
            break;
          case "TOURS":
            paxesAux.push({
              clientId: paxes[i].clientId,
              gender: paxes[i].gender,
              title: paxes[i].title,
              firstName: paxes[i].first_name,
              lastName: paxes[i].last_name,
              identificationType: paxes[i].identificationType,
              identification: paxes[i].document_number,
              type: paxes[i].type === "AD" ? "AD" : "CH",
              birth_date: birth_date,
              age: Age,
              email: paxes[i].email,
              phone: paxes[i].phone,
              address: paxes[i].address,
              nationality: "AR",
              country: paxes[i].country,
              city: paxes[i].city,
              idUnique: paxes[i].idUnique,
              state: paxes[i].state_id,
              relation_ship_catalogue_id: paxes[i].relation_ship_catalogue_id,
              holder: i == 0 ? true : false,
            });
            n = i + 1;
            break;
          case "RENT-A-CAR":
            paxesAux.push({
              clientId: paxes[i].clientId,
              gender: paxes[i].gender,
              title: paxes[i].title,
              firstName: paxes[i].first_name,
              lastName: paxes[i].last_name,
              identificationType: paxes[i].identificationType,
              identification: paxes[i].document_number,
              type: paxes[i].type === "AD" ? "AD" : "CH",
              birth_date: birth_date,
              age: Age,
              email: paxes[i].email,
              phone: paxes[i].phone,
              address: paxes[i].address,
              nationality: "AR",
              country: paxes[i].country,
              city: paxes[i].city,
              idUnique: paxes[i].idUnique,
              state: paxes[i].state_id,
              relation_ship_catalogue_id: paxes[i].relation_ship_catalogue_id,
              holder: i == 0 ? true : false,
            });
            n = i + 1;
            break;
        }
      }
    }
    console.log(paxLength);
    console.log(itemId);
    if (paxLength > 0 && itemId === undefined) {
      const paxAux = pax;
      console.log(paxAux);
      for (let i = 0; i < paxAux.length; i++) {
        const birth_date = moment(paxAux[i].birth_date, ["YYYY-DD-MM", "DD-MM-YYYY", "YYYY-MM-DD", "MM-DD-YYYY"]).format("YYYY-MM-DD");
        console.log(birth_date);
        const Age = moment().diff(birth_date, "years");
        console.log(Age);
        switch (category) {
          case "HOTELS":
            console.log(paxAux[i]);
            console.log(paxAux[i].relation_ship_catalogue_id);
            paxesAux.push({
              clientId: paxAux[i].clientId ? paxAux[i].clientId : null,
              gender: paxAux[i].gender ? paxAux[i].gender : null,
              title: paxAux[i].title ? paxAux[i].title : null,
              firstName: paxAux[i].first_name ? paxAux[i].first_name : null,
              lastName: paxAux[i].last_name ? paxAux[i].last_name : null,
              identificationType: paxAux[i].identificationType ? paxAux[i].identificationType : null,
              identification: paxAux[i].document_number ? paxAux[i].document_number : null,
              type: paxAux[i].type === "AD" ? "AD" : "CH",
              birth_date: paxAux[i].birth_date ? paxAux[i].birth_date : null,
              age: Age ? Age : null,
              email: paxAux[i].email ? paxAux[i].email : null,
              phone: paxAux[i].phone ? paxAux[i].phone : null,
              address: paxAux[i].address ? paxAux[i].address : null,
              country: paxAux[i].country_id ? paxAux[i].country_id : null,
              city: paxAux[i].city ? paxAux[i].city : null,
              idUnique: paxAux[i].idUnique ? paxAux[i].idUnique : null,
              state: paxAux[i].state_id ? paxAux[i].state_id : null,
              relation_ship_catalogue_id: paxAux[i].relation_ship_catalogue_id ? paxAux[i].relation_ship_catalogue_id : null,
              holder: i == 0 ? true : false,
            });
            n = i + 1;
            break;
          case "TOURS":
            console.log(paxAux[i]);
            console.log("paxaux", paxAux[i].clientId);
            console.log(paxAux[i].relation_ship_catalogue_id);
            paxesAux.push({
              clientId: paxAux[i].clientId ? paxAux[i].clientId : null,
              gender: paxAux[i].gender ? paxAux[i].gender : null,
              title: paxAux[i].title ? paxAux[i].title : null,
              firstName: paxAux[i].first_name ? paxAux[i].first_name : null,
              lastName: paxAux[i].last_name ? paxAux[i].last_name : null,
              identificationType: paxAux[i].identificationType ? paxAux[i].identificationType : null,
              identification: paxAux[i].document_number ? paxAux[i].document_number : null,
              type: paxAux[i].type === "AD" ? "AD" : "CH",
              birth_date: paxAux[i].birth_date ? paxAux[i].birth_date : null,
              age: Age ? Age : null,
              email: paxAux[i].email ? paxAux[i].email : null,
              phone: paxAux[i].phone ? paxAux[i].phone : null,
              address: paxAux[i].address ? paxAux[i].address : null,
              country: paxAux[i].country_id ? paxAux[i].country_id : null,
              city: paxAux[i].city ? paxAux[i].city : null,
              idUnique: paxAux[i].idUnique ? paxAux[i].idUnique : null,
              state: paxAux[i].state_id ? paxAux[i].state_id : null,
              relation_ship_catalogue_id: paxAux[i].relation_ship_catalogue_id ? paxAux[i].relation_ship_catalogue_id : null,
              holder: i == 0 ? true : false,
            });
            n = i + 1;
            break;
          case "RENT-A-CAR":
            console.log(paxAux[i]);
            console.log("paxaux", paxAux[i].clientId);
            console.log(paxAux[i].relation_ship_catalogue_id);
            paxesAux.push({
              clientId: paxAux[i].clientId ? paxAux[i].clientId : null,
              gender: paxAux[i].gender ? paxAux[i].gender : null,
              title: paxAux[i].title ? paxAux[i].title : null,
              firstName: paxAux[i].first_name ? paxAux[i].first_name : null,
              lastName: paxAux[i].last_name ? paxAux[i].last_name : null,
              identificationType: paxAux[i].identificationType ? paxAux[i].identificationType : null,
              identification: paxAux[i].document_number ? paxAux[i].document_number : null,
              type: paxAux[i].type === "AD" ? "AD" : "CH",
              birth_date: paxAux[i].birth_date ? paxAux[i].birth_date : null,
              age: Age ? Age : null,
              email: paxAux[i].email ? paxAux[i].email : null,
              phone: paxAux[i].phone ? paxAux[i].phone : null,
              address: paxAux[i].address ? paxAux[i].address : null,
              country: paxAux[i].country_id ? paxAux[i].country_id : null,
              city: paxAux[i].city ? paxAux[i].city : null,
              idUnique: paxAux[i].idUnique ? paxAux[i].idUnique : null,
              state: paxAux[i].state_id ? paxAux[i].state_id : null,
              relation_ship_catalogue_id: paxAux[i].relation_ship_catalogue_id ? paxAux[i].relation_ship_catalogue_id : null,
              holder: i == 0 ? true : false,
            });
            n = i + 1;
            break;
        }
      }
    }
    console.log(paxesAux);
    return paxesAux;
  }

  confirmationRedirect(reservationId: number) {

    if (!this.partialReserve) {
      let goToURL: string;
      let navigationExtras: NavigationExtras;

      goToURL = "DetailsSummaryItinerary";
      navigationExtras = {
        queryParams: {
          from: "save-draft-reserve",
          itineraryId: this.itineraryId,
        },
      };
      this.route.navigate([goToURL], navigationExtras);
    } else {
      let goToURL: string;
      let navigationExtras: NavigationExtras;
      goToURL = "DetailsSummaryItinerary";
      navigationExtras = {
        queryParams: {
          from: "partial-reserve",
          itineraryId: this.itineraryId,
        },
      };
      this.route.navigate([goToURL], navigationExtras);

    }
  }

  postMainTransaction(result) {
    let json = JSON.parse(localStorage.getItem("nrPayment"));

    json.paymentMethods.forEach((element) => {
      element.referenceId = result.id;
    });
    console.log(json)
    this._restService.doPost("app", "transactionMaster", json).subscribe((response: any) => {
      console.log("responser transaction master", response);
    });
  }

  private _questionsPayload(item: any, pax: any) {
    // console.log('item questions', item);
    // console.log('item paxes questions', pax[0]);
    // console.log('item questions', item.questions);

    let questPayload = [];
    let paxArr = pax[0];

    for (let i = 0; i < item.questions.length; i++) {
      const element = item.questions[i];
      // console.log('element questions ->', element);
      for (let i = 0; i < paxArr.length; i++) {
        let questDataPayload;

        switch (element.code) {
          case "HOTEL":
            questDataPayload = {
              code: element.code,
              text: this.namehotel,
              required: element.required,
            };
            break;
          case "HOTEL_NAME":
            questDataPayload = {
              code: element.code,
              text: this.namehotel,
              required: element.required,
            };
            break;
          case "PHONENUMBER":
            questDataPayload = {
              code: element.code,
              text: paxArr[i].phone,
              required: element.required,
            };
            break;
          case "EMAIL":
            questDataPayload = {
              code: element.code,
              text: paxArr[i].email,
              required: element.required,
            };
            break;
          case "MOBILE":
            questDataPayload = {
              code: element.code,
              text: paxArr[i].phone,
              required: element.required,
            };
            break;
          case "NACIONALIDAD":
            questDataPayload = {
              code: element.code,
              text: paxArr[0].country_id,
              required: element.required,
            };
            break;
          case "PASAPORTE":
            questDataPayload = {
              code: element.code,
              text: paxArr[i].document_number,
              required: element.required,
            };
            break;
          case "PAX NAME":
            questDataPayload = {
              code: element.code,
              text: paxArr[i].first_name + " " + paxArr[i].last_name,
              required: element.required,
            };
            break;
        }
        questPayload.push(questDataPayload);
      }
    }
    return questPayload;
  }

  selectNull() {
    if (this.paxSelect) {
      this.paxSelect.forEach((pax) => {
        pax.nativeElement.value = null;
      });
    }
  }
}
